import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldRadioButton } from '../../components';

import css from './PaymentSelectionForm.module.css';

const PaymentSelectionFormComponent = props => (
  <FinalForm
    {...props}
    initialValues={{
      paymentMethod: 'creditCard'
    }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        handleSubmit,
        inProgress,
        intl,
        values,
        isPremiumUser
      } = fieldRenderProps;

      const creditCardLabel = intl.formatMessage({
        id: 'PaymentSelectionPage.creditCardLabel',
      });

      const cashLabel = intl.formatMessage({
        id: 'PaymentSelectionPage.cashLabel',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = !values?.paymentMethod || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="PaymentSelectionPage">

          <h2 className={css.heading}>
            <FormattedMessage id="PaymentSelectionPage.youWantPayWith" />
          </h2>

          <div className={css.radioButtonRow}>
            <FieldRadioButton
              id="creditCard"
              name="paymentMethod"
              label={creditCardLabel}
              value="creditCard"
            />
            <FieldRadioButton
              disabled={!isPremiumUser}
              id="cash"
              name="paymentMethod"
              label={cashLabel}
              value="cash"
              isPremiumUser={isPremiumUser}
            />
          </div>
          <div className={submitButtonWrapperClassName}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="PaymentSelectionPage.nextBtn" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

const { string, bool } = PropTypes;


PaymentSelectionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isPremiumUser: false
};

PaymentSelectionFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  // from injectIntl
  intl: intlShape.isRequired,
  isPremiumUser: bool
};

const PaymentSelectionForm = compose(injectIntl)(PaymentSelectionFormComponent);

PaymentSelectionForm.displayName = 'PaymentSelectionForm';

export default PaymentSelectionForm;
