import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingQuantityForm } from '../../forms';
import config from '../../config';

import css from './EditListingQuantityPanel.module.css';
import {getDefaultTimeZoneOnBrowser} from "../../util/dates";
const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const EditListingQuantityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const availabilityPlanType = publicData && publicData.availability ? publicData.availability : null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingQuantityPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingQuantityPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingQuantityForm
        className={css.form}
        initialValues={{
          quantity: publicData.quantity,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { quantity } = values;
          const defaultAvailabilityPlan = availabilityPlanType === 'daily' ? {
            type: 'availability-plan/day',
            entries: [
              { dayOfWeek: 'mon', seats: quantity },
              { dayOfWeek: 'tue', seats: quantity },
              { dayOfWeek: 'wed', seats: quantity },
              { dayOfWeek: 'thu', seats: quantity },
              { dayOfWeek: 'fri', seats: quantity },
              { dayOfWeek: 'sat', seats: quantity },
              { dayOfWeek: 'sun', seats: quantity },
            ],
          } : {
            type: 'availability-plan/time',
            timezone: defaultTimeZone(),
            entries: [
              { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: quantity },
              { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: quantity },
              { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: quantity },
              { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: quantity },
              { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: quantity },
              { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: quantity },
              { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: quantity },
            ],
          };

          const availabilityPlan = currentListing.attributes.availabilityPlan;
          const currentQuantity = availabilityPlan?.entries && availabilityPlan?.entries[0]?.seats;
          const currentAvailabilityPlan = currentQuantity === undefined || currentQuantity !== quantity ? defaultAvailabilityPlan : availabilityPlan;

          const updateValues = {
            availabilityPlan: currentAvailabilityPlan,
            publicData: { quantity },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={config.custom.categories}
      />
    </div>
  );
};

EditListingQuantityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingQuantityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingQuantityPanel;
