export const Categories = [
  // {
  //   name: '🏩 Profesionāļu pakalpojumi',
  //   id: 'tuv_telpas',
  //   subcategories: [
  //     {
  //       name: 'Conference rooms',
  //       id: 'tuv_konferencu_telpas',
  //       apraksts: 'konferences, tiksanas, mitini, preses brifingi',
  //     },
  //     {
  //       name: 'Cafes for private events',
  //       id: 'tuv_stigu',
  //       apraksts: 'stiligas telpas pasakumiem',
  //     },
  //     {
  //       name: 'Concert halls',
  //       id: 'tuv_zales',
  //       apraksts: 'zales koncertiem, skatuves',
  //     },
  //     {
  //       name: 'Sports centres and fields',
  //       id: 'tuv_sports',
  //       apraksts: 'Taures un citi, sporta zales, basketbols, volejbols, futbols',
  //     },
  //     {
  //       name: 'Outdoor areas',
  //       id: 'tuv_teritorijas',
  //       apraksts: 'koncertiem, estrades',
  //     },
  //     {
  //       name: 'Recoarding studios and rehearsal rooms',
  //       id: 'tuv_studijas',
  //       apraksts: 'mēģi, meginajumu zales, record studio',
  //     },
  //     {
  //       name: 'Other venues',
  //       id: 'tuv_citas',
  //       apraksts: 'dažādas telpas',
  //     },
  //   ],
  // },

  {
    name: '🎤 Audio un video tehnika',
    id: 'auv_tehnika',
    subcategories: [
      {
        name: 'Audio tehnika',
        id: 'auv_audio',
        apraksts: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
        keywords: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
      },
      {
        name: 'Online pasākumu risinājumi',
        id: 'auv_risinājumi',
        apraksts: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
        keywords: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
      },

      {
        name: 'DJ galdi',
        id: 'auv_dj_mixers',
        apraksts: '',
      },

      {
        name: 'Apskaņošanas tehnikas komplekti',
        id: 'auv_sound_equipment_sets',
        apraksts: '',
      },

      {
        name: 'LED ekrāni',
        id: 'auv_led_screens',
        apraksts: '',
      },

      {
        name: 'TV ekrāni',
        id: 'auv_tv_screens',
        apraksts: '',
      },

      {
        name: 'Projektori un projektoru ekrāni',
        id: 'auv_projectors_screens',
        apraksts: '',
      },

      {
        name: 'Foto un video tehnika',
        id: 'auv_photo_video_equipment',
        apraksts: '',
      },

      {
        name: 'Citi',
        id: 'auv_other_equipment',
        apraksts: '',
        other: true,
      },
      //
      // {
      //   name: 'Foto and video equipment',
      //   id: 'auv_fotovideo',
      //   apraksts: 'kameras videokameras fotokameras filmēšanas laukumi stendi gaismas kastes',
      // },
      // {
      //   name: 'Concert audio/video equipment',
      //   id: 'auv_konc_aparatura',
      //   apraksts: 'skaļruņi pastiprinātāji vadi',
      // },
      // {
      //   name: 'Screens and projectors',
      //   id: 'auv_ekrani',
      //   apraksts: 'led ekrāni',
      // },
      // {
      //   name: 'Communication technologies',
      //   id: 'auv_sakari',
      //   apraksts: 'rācijas komunikācija',
      // },
    ],
  },
  {
    name: '⛩ Dekorācijas',
    id: 'mui_decorations',
    subcategories: [
      {
        name: 'Dekorāciju komplekti',
        id: 'mui_decoration_sets',
        apraksts: 'Decoration',
      },

      {
        name: 'Dekoratīvi elementi',
        id: 'mui_decorative_elements',
        apraksts: 'Decoration',
      },

      {
        name: 'Tematiskie dekori',
        id: 'mui_decorations_of_different_themes',
        apraksts: 'Decoration',
      },

      {
        name: 'Foto sienas',
        id: 'mui_photo_walls',
        apraksts: 'Decoration',
      },

      {
        name: 'Galda dekori',
        id: 'mui_table_decorations',
        apraksts: 'Decoration',
      },

      {
        name: 'Arkas',
        id: 'mui_arches',
        apraksts: 'Decoration',
      },

      {
        name: 'Augi un ziedu dekori',
        id: 'mui_plants_and_floristic_decorations',
        apraksts: 'Decoration',
      },

      {
        name: 'Mākslīgie tepaugi',
        id: 'mui_artificial_plants',
        apraksts: 'Decoration',
      },

      {
        name: 'Citi',
        id: 'mui_other_decoration',
        apraksts: 'Decoration',
        other: true,
      },

    ],
  },
  {
    name: '🍽 Ēdināšana',
    id: 'mui_catering',
    subcategories: [
      {
        name: 'Banketu serviss',
        id: 'pserv_banquet_services',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Treileri, izbraukumu tirdzniecība',
        id: 'pserv_food_trucks_mobile_catering',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Viesmīļi un bārmeņi',
        id: 'pserv_waiters_and_bartenders',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Trauki',
        id: 'pserv_dishes_and_tableware',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Ledusskapji un saldētavas',
        id: 'pserv_refrigerators_and_freezers',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Citi',
        id: 'pserv_other_catering',
        apraksts: 'Ēdināšana',
        other: true,
      },

    ],
  },
  {
    name: '💡 Gaismas',
    id: 'gus_main',
    subcategories: [
      {
        name: 'Gaismu komplekti',
        id: 'gus_lampinas',
        apraksts: 'Apraksts',
      },

      {
        name: 'Gaismas objekti',
        id: 'gus_light_objects',
        apraksts: 'Apraksts',
      },

      {
        name: 'Dekoratīvais apgaismojums',
        id: 'gus_decorative_lighting',
        apraksts: 'Apraksts',
      },

      {
        name: 'Lampiņu virtenes',
        id: 'gus_chain_lights',
        apraksts: 'Apraksts',
      },

      {
        name: 'Citi',
        id: 'gus_other_lights',
        apraksts: 'Apraksts',
        other: true,
      },

      //
      // {
      //   name: 'Indoor lighting',
      //   id: 'gus_lampas_griestu',
      //   apraksts: 'Griestu lampas, stāvlampas, stavlampas, galda lampas, istabas',
      // },
      //
      // {
      //   name: 'Spotlights',
      //   id: 'gus_prozektori',
      //   apraksts: 'prozektori',
      // },
      //
      // {
      //   name: 'LED objects',
      //   id: 'gus_led',
      //   apraksts: 'Apraksts',
      // },
      //
      // {
      //   name: 'Non-standart lighting',
      //   id: 'gus_nest_gaismas',
      //   apraksts: 'visadas divainas gaismas, visādas dīvainas gaismas, interesantas',
      // },

    ],
  },
  {
    name: '🚏 Info stendi un norādes',
    id: 'stands',
    subcategories: [
      {
        name: 'Info stendi',
        id: 'info_stands',
        apraksts: '',
      },

      {
        name: 'Baneru statīvi un rāmji',
        id: 'banner_stands_frames',
        apraksts: '',
      },

      {
        name: 'Karogu masti',
        id: 'flagpoles',
        apraksts: '',
      },

      {
        name: 'Norādes',
        id: 'signs',
        apraksts: '',
      },

      {
        name: 'Citi',
        id: 'other_signs',
        apraksts: '',
        other: true,
      },

    ],
  },
  {
    name: '🛋 Mēbeles',
    id: 'mui_mebeles',
    subcategories: [
      {
        name: 'Galdi un letes',
        id: 'galdi',
        apraksts: 'Apraksts',
      },
      {
        name: 'Krēsli',
        id: 'kresli',
        apraksts: 'Apraksts',
      },
      {
        name: 'Dīvāni un pufi',
        id: 'divani',
        apraksts: 'Apraksts',
      },
      {
        name: 'Podesti',
        id: 'podiums',
        apraksts: 'Apraksts',
      },

      {
        name: 'LED mēbeles',
        id: 'led_mebeles',
        apraksts: 'Apraksts',
      },

      {
        name: 'Spoguļi',
        id: 'mirrors',
        apraksts: 'Apraksts',
      },

      {
        name: 'Stendi, statīvi',
        id: 'stativi',
        apraksts: 'sienas, žogi, zogi',
      },

      {
        name: 'Plaukti, skapji, kumodes',
        id: 'skapji',
        apraksts: 'Apraksts',
      },

      {
        name: 'Āra mēbeles',
        id: 'skapji',
        apraksts: 'Apraksts',
      },

      {
        name: 'Citi',
        id: 'other_furniture',
        apraksts: 'Apraksts',
        other: true,
      },

      // {
      //   name: 'Divider screens',
      //   id: 'mui_aizslietni',
      //   apraksts: 'Apraksts',
      // },
      // {
      //   name: 'Storage boxes',
      //   id: 'mui_kastes',
      //   apraksts: 'uzglabasana',
      // },
      //
      // {
      //   name: 'Clothing racks',
      //   id: 'mui_garderobes',
      //   apraksts: 'Apraksts',
      // },
    ],
  },
  {
    name: '🌳 Pasākumu aprīkojums un tehnika',
    id: 'tut_event_equipment',
    subcategories: [
      {
        name: 'Sildītāji',
        id: 'gus_silditaji',
        apraksts: 'Radiatori',
      },

      {
        name: 'Ventilatori',
        id: 'gus_fans',
        apraksts: '',
      },

      {
        name: 'Sanitārais aprīkojums',
        id: 'aaut_sanitarais',
        apraksts: 'santehnika, tualetes, podi, izlietnes, udens, ūdens',
      },

      {
        name: 'Elektroinstalācija',
        id: 'aaut_elektro',
        apraksts: 'eletriba',
      },

      {
        name: 'Norobežojošie žogi',
        id: 'aaut_zogi',
        apraksts: 'Apraksts',
      },

      {
        name: 'Rācijas un intercom',
        id: 'aaut_communication_equipment',
        apraksts: '',
      },

      {
        name: 'Datori, telefoni, planšetes',
        id: 'aaut_computers_smartphones_tablets',
        apraksts: '',
      },

      {
        name: 'Lādēšanas galdi',
        id: 'aaut_charging_tablets',
        apraksts: '',
      },

      {
        name: 'Citi',
        id: 'aaut_event_equipment',
        apraksts: '',
        other: true,
      },

    ],
  },

  {
    name: '🎉 Pasākuma saturs',
    id: 'mui_event_content',
    subcategories: [
      {
        name: 'Pasākumu vadītāji',
        id: 'pserv_vaditaji',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },
      {
        name: 'Pirtis un baļļas',
        id: 'up_to_50_people',
        apraksts: '',
      },
      {
        name: 'Mūziķi',
        id: 'pserv_musicians',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'DJ',
        id: 'pserv_dj',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'Skatuves mākslinieki un performatori',
        id: 'pserv_performing_artists',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'Aktivitātes un spēles',
        id: 'pserv_acitivities_and_games',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'Citi',
        id: 'pserv_Other',
        apraksts: 'cirka šovi, bārmeņu šovi',
        other: true,
      },
    ],
  },
  {
    name: '🦸🏻 Profesionāļu pakalpojumi',
    id: 'pserv_main',
    subcategories: [

      {
        name: 'Fotogrāfi',
        id: 'pserv_fotografi',
        apraksts: 'VJ, video operatori, video animatori',
      },
      {
        name: 'Montāžas pakalpojumi',
        id: 'montāžas_pakalpojumi',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Video mākslinieki',
        id: 'pserv_video_producers',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Vizāžisti, stilisti, frizieri',
        id: 'pserv_vizazisti',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Dizaineri un scenogrāfi',
        id: 'pserv_designers_and_scenographers',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Scenāriju autori',
        id: 'pserv_seenwriters',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Skaņas un gaismu mākslinieki',
        id: 'pserv_light_and_sound_engineers',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Pasākumu darbinieki',
        id: 'pserv_event_staffs',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Korektori un tulki',
        id: 'pserv_text_correctors_and_translators',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Uzkopšanas serviss',
        id: 'pserv_cleaning_services',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Citi',
        id: 'pserv_other',
        apraksts: 'VJ, video operatori, video animatori',
        other: true,
      },

      //
      // {
      //   name: 'Designers and programmers',
      //   id: 'pserv_programmesana',
      //   apraksts: 'dizains, maketēšana, dagnis, edmunds',
      // },
      // {
      //   name: 'Performers, musicians',
      //   id: 'pserv_muziki',
      //   apraksts:
      //     'Dziedātāji, izpildītāji, dziedataji, izpilditaji, grupas, bunģieri, ģitāristi, vijolisti, klavieru spēlētāji',
      // },
      //

      // {
      //   name: 'Event managers',
      //   id: 'pserv_organizatori',
      //   apraksts: 'freelanceri, menedzeri, organizetaji, andis',
      // },
      // {
      //   name: 'Promoters',
      //   id: 'pserv_promoteri',
      //   apraksts: 'reklāmisti, reklamas specialisti, reklāmas speciālisti',
      // },
      // {
      //   name: 'Event technicians',
      //   id: 'pserv_tehniskie',
      //   apraksts: 'Strādnieki, stradnieki, elektriki',
      // },
      //
      // {
      //   name: 'Game and activity planners',
      //   id: 'pserv_aktivitates',
      //   apraksts: 'speles un aktivitates',
      // },
      // {
      //   name: 'Light engineer',
      //   id: 'pserv_gaismotaji',
      //   apraksts: 'gaismas, prozektori',
      // },
      // {
      //   name: 'Sound engineer',
      //   id: 'pserv_skanotaji',
      //   apraksts: 'skanotaji, didzeji, dīdžeji',
      // },
      // {
      //   name: 'Chefs, waiters, bartenders',
      //   id: 'pserv_pavari',
      //   apraksts: 'pavari, viesmili, barmeni, virtuves darbinieki',
      // },
      // {
      //   name: 'Scenographers and artists',
      //   id: 'pserv_scenografi',
      //   apraksts: 'Skatuvju iekārtotāji, dagnis',
      // },
      // {
      //   name: 'Translators and script writers',
      //   id: 'pserv_tulki',
      //   apraksts: 'Rakstnieki, scenaristi, dzejnieki, tekstu autori',
      // },

    ],
  },
  {
    name: '⛺️ Teltis un nojumes',
    id: 'tun_teltis',
    subcategories: [
      {
        name: 'Lielas teltis',
        id: 'tun_lielas_teltis',
        apraksts: 'Apraksts',
      },
      {
        name: 'Vidējas un mazas teltis',
        id: 'tun_mazas-lielas-teltis',
        apraksts: 'Caurspidigas, caurspīdīgas',
      },
      {
        name: 'Telšu karnīzes un baneru rāmji',
        id: 'tun_tent_cornices_banner_frames',
        apraksts: '',
      },
      {
        name: 'Nestandarta nojumes',
        id: 'tun_non_standart_shelters',
        apraksts: '',
      },
      {
        name: 'Telšu grīdas',
        id: 'tun_tent_flooring',
        apraksts: '',
      },

      {
        name: 'Citi',
        id: 'tun_other_shelters',
        apraksts: '',
        other: true,
      },
    ],
  },

  {
    name: '🏩 Pasākumu norises vietas',
    id: 'event_venues',
    subcategories: [
      {
        name: 'Līdz 50 cilvēkiem',
        id: 'up_to_50_people',
        apraksts: '',
      },
      {
        name: 'Līdz 150 cilvēkiem',
        id: 'up_to_150_people',
        apraksts: '',
      },

      {
        name: 'Līdz 300 cilvēkiem',
        id: 'up_to_400_people',
        apraksts: '',
      },

      {
        name: 'Virs 300 cilvēkiem',
        id: 'over_400_people',
        apraksts: '',
      },

      {
        name: 'Āra pasākumiem',
        id: 'outdoor_event_areas',
        apraksts: '',
      },

      {
        name: 'Mēģinājumu telpas',
        id: 'rehearsal_rooms',
        apraksts: '',
      },

      {
        name: 'Citas vietas',
        id: 'other_event_venues',
        apraksts: '',
      },



    ],
  },

  {
    name: '🎪 Skatuves un komplektācijas',
    id: 'mui_stages',
    subcategories: [
      {
        name: 'Skatuvju konstrukcijas',
        id: 'mui_stage_constructions',
        apraksts: 'Stage',
      },

      {
        name: 'Pilns skatuves komplekts',
        id: 'mui_full_stage_set',
        apraksts: 'Stage',
      },

      {
        name: 'Podestūra',
        id: 'mui_stage_decks',
        apraksts: 'Stage',
      },

      {
        name: 'Citi',
        id: 'mui_other',
        apraksts: 'Stage',
        other: true,
      },

    ],
  },
  {
    name: '🚐 Transports',
    id: 'tut_transport',
    subcategories: [
      {
        name: 'Kravas transports ',
        id: 'tut_cargo',
        apraksts: '',
      },

      {
        name: 'Pasažieru pārvadājumu transports',
        id: 'tut_passenger_transport',
        apraksts: '',
      },

      {
        name: 'Vieglie un tematiskie auto',
        id: 'tut_cars_themed_vehicles',
        apraksts: '',
      },

      {
        name: 'Piekabes',
        id: 'tut_trailers',
        apraksts: '',
      },

      {
        name: 'Būvdarbu tehnika',
        id: 'tut_heavy_construction_equipment',
        apraksts: '',
      },

      {
        name: 'Citi',
        id: 'tut_other_transport',
        apraksts: '',
        other: true,
      },

    ],
  },
  {
    name: '👗 Tekstils un tērpi',
    id: 'tut_terpi',
    subcategories: [
      {
        name: 'Galda klājumi',
        id: 'tut_galdauti',
        apraksts: 'Šīs ir tiešām lielas kleitas',
      },
      {
        name: 'Krēslu pārvalki',
        id: 'tut_parvalki',
        apraksts: 'Šīs ir tiešām mazas kleitas',
      },

      {
        name: 'Drapērijas un aizkari',
        id: 'tut_drapery_curtains',
        apraksts: '',
      },

      {
        name: 'Paklāji',
        id: 'tut_paklaji',
        apraksts: 'Šīs ir tiešām īpašas kleitas',
      },

      {
        name: 'Tērpu noma',
        id: 'tut_terpu_noma',
        apraksts:
          'Bikses, krekli, drēbes, drebes, kleitas, uzvalki, smokingi, šlipses, kurpes, zābaki, kostīmi',
      },

      {
        name: 'Šuvēji',
        id: 'tut_tailora',
        apraksts: '',
      },

      {
        name: 'Citi',
        id: 'tut_other_textile',
        apraksts: '',
        other: true,
      },

      //
      // {
      //   name: 'Chair cushions',
      //   id: 'tut_spilveni',
      //   apraksts: 'Šīs ir tiešām īpašas kleitas',
      // },
      // {
      //   name: 'Decorative fabrics',
      //   id: 'tut_audumi',
      //   apraksts: 'Šīs ir tiešām citas kleitas',
      // },
      // {
      //   name: 'Drapery',
      //   id: 'tut_draperijas',
      //   apraksts: 'Šīs ir tiešām lielas kleitas',
      // },
      // {
      //   name: 'Curtains',
      //   id: 'tut_aizkari',
      //   apraksts: 'Šīs ir tiešām mazas kleitas',
      // },
      //
      // {
      //   name: 'Napkins',
      //   id: 'tut_salvetes',
      //   apraksts: 'Šīs ir tiešām citas kleitas',
      // },
      // {
      //   name: 'Flag strings/Bunting',
      //   id: 'tut_karodzini',
      //   apraksts: 'Šīs ir tiešām īpašas kleitas',
      // },

    ],
  },

  // {
  //   name: '⛩ Constructions and decors',
  //   id: 'suk_konstrukcijas',
  //   subcategories: [
  //     {
  //       name: 'Large format constructions',
  //       id: 'suk_lielas_konstrukcijas',
  //       apraksts: 'skatuves, skatuvju',
  //     },
  //     {
  //       name: 'Arches and entrances',
  //       id: 'suk_arkas',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Wall and ceiling decorations',
  //       id: 'suk_dekori',
  //       apraksts: 'sienas, griesti, gleznas',
  //     },
  //     {
  //       name: 'Decorations of different themes',
  //       id: 'suk_tem_dekori',
  //       apraksts: 'Dekori īpašām tēmām',
  //     },
  //     {
  //       name: 'Decorative objects',
  //       id: 'suk_dek_objekti',
  //       apraksts: 'dekoraciajs',
  //     },
  //     {
  //       name: 'Floristic decorations',
  //       id: 'suk_ziedu_dek',
  //       apraksts: 'ziedi, rozes, tulpes',
  //     },
  //   ],
  // },
  // {
  //   name: '🚐 Vechiles and trucks',
  //   id: 'daust_smaga-tehnika',
  //   subcategories: [
  //     {
  //       name: 'Truck',
  //       id: 'daust_kravas',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Regular vechile',
  //       id: 'daust_vieglas_masinas',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Non-standart vechile',
  //       id: 'daust_nestandarta_auto',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Electrical instruments',
  //       id: 'daust_elektroinstrumenti',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Heavy construction equipment',
  //       id: 'daust_smagie',
  //       apraksts: 'Apraksts',
  //     },
  //   ],
  // },
  // {
  //   name: '🌳 Outdoor equipment',
  //   id: 'aaut_araaprikojums',
  //   subcategories: [
  //     {
  //       name: 'Outdoor heaters',
  //       id: 'aaut_ara_silditaji',
  //       apraksts: 'siltums',
  //     },
  //
  //
  //
  //     {
  //       name: 'Outdoor furniture',
  //       id: 'aaut_ara_mebeles',
  //       apraksts: 'Ara kresli, āra krēsli, galdi, gultas, krēsliņi',
  //     },
  //   ],
  // },
  // {
  //   name: '🚏 Advertising stands and signs',
  //   id: 'rsun_brendings',
  //   subcategories: [
  //     {
  //       name: 'Stands',
  //       id: 'rsun_stendi',
  //       apraksts: 'Šīs ir tiešām lielas norādes',
  //     },
  //     {
  //       name: 'Banner stands and frames',
  //       id: 'rsun_baneri',
  //       apraksts: 'Šīs ir tiešām lielas norādes',
  //     },
  //     {
  //       name: 'Signs and other...',
  //       id: 'rsun_norades',
  //       apraksts: 'Šīs ir tiešām mazas norādes',
  //     },
  //   ],
  // },
  // {
  //   name: '🍽 Cutlery and decorations',
  //   id: 'gpud_galda_dekori',
  //   subcategories: [
  //     {
  //       name: 'Tableware',
  //       id: 'gpud_trauki',
  //       apraksts:
  //         'glāzes, šķīvji, dakšiņas, naži, karotes, bļodas, krūzes, glazes, skivji, daksinas, nazi, blodas, kruzes',
  //     },
  //     {
  //       name: 'Vases',
  //       id: 'gpud_vazes',
  //       apraksts: 'vazes, kruzes, puku, puķu podi',
  //     },
  //     {
  //       name: 'Table decorations',
  //       id: 'gpud_gald_dek',
  //       apraksts: 'dekoracijas, dekorācijas, dekorējumi',
  //     },
  //     {
  //       name: 'Food stands',
  //       id: 'gpud_ed_stendi',
  //       apraksts: 'sokolades, fonteini, kūkas, kūku',
  //     },
  //     {
  //       name: 'Indoor plant rent',
  //       id: 'gpud_augu_noma',
  //       apraksts: 'augi',
  //     },
  //   ],
  // },
  // {
  //   name: '🏗 Manufacturers, Polygraphy agencies, builders',
  //   id: 'rdb_main',
  //   subcategories: [
  //     {
  //       name: 'Manufacturing',
  //       id: 'rdb_konstrukciju_razotaij',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Printing and pasting/application',
  //       id: 'rdb_drukataji',
  //       apraksts: 'Baneri, reklāmas, uzlīmes, flaijeri',
  //     },
  //     {
  //       name: 'Carpentry and metalwork',
  //       id: 'rdb_galdnieki',
  //       apraksts: 'Metālstrādnieki, metalstradnieki',
  //     },
  //     {
  //       name: 'Construction workers',
  //       id: 'rdb_buvdarbu',
  //       apraksts: 'Izgatavotaji',
  //     },
  //     {
  //       name: 'Transporters and loaders',
  //       id: 'rdb_transports',
  //       apraksts: 'kravataji, parvadataji, pārvadātāji',
  //     },
  //   ],
  // },
  // {
  //   name: '🎸 Musical instruments',
  //   id: 'mi_instrumenti',
  //   subcategories: [
  //     {
  //       name: 'Keyboard instruments',
  //       id: 'mi_taustini',
  //       apraksts: 'Klavieres, sintezatori, taustininstrumenti, piano',
  //     },
  //     {
  //       name: 'String instruments',
  //       id: 'mi_stigu',
  //       apraksts: 'Ģitāras, gitaras, vijoles, kontrabasi, celli, čelli',
  //     },
  //     {
  //       name: 'Percussion instruments',
  //       id: 'mi_sitamie',
  //       apraksts: 'Bungas, klavesīni, skivji, šķīvji',
  //     },
  //     {
  //       name: 'Wind instruments',
  //       id: 'mi_pusamie',
  //       apraksts: 'Taures, tromboni, flautas, mežragi, saksafoni',
  //     },
  //     {
  //       name: 'Other instruments',
  //       id: 'mi_citi_instrumenti',
  //       apraksts: 'Visādi instrumenti',
  //     },
  //   ],
  // },
];

export const CategoriesEn = [
  // {
  //   name: '🏩 Venues and event places',
  //   id: 'tuv_telpas',
  //   subcategories: [
  //     {
  //       name: 'Conference rooms',
  //       id: 'tuv_konferencu_telpas',
  //       apraksts: 'konferences, tiksanas, mitini, preses brifingi',
  //     },
  //     {
  //       name: 'Cafes for private events',
  //       id: 'tuv_stigu',
  //       apraksts: 'stiligas telpas pasakumiem',
  //     },
  //     {
  //       name: 'Concert halls',
  //       id: 'tuv_zales',
  //       apraksts: 'zales koncertiem, skatuves',
  //     },
  //     {
  //       name: 'Sports centres and fields',
  //       id: 'tuv_sports',
  //       apraksts: 'Taures un citi, sporta zales, basketbols, volejbols, futbols',
  //     },
  //     {
  //       name: 'Outdoor areas',
  //       id: 'tuv_teritorijas',
  //       apraksts: 'koncertiem, estrades',
  //     },
  //     {
  //       name: 'Recoarding studios and rehearsal rooms',
  //       id: 'tuv_studijas',
  //       apraksts: 'mēģi, meginajumu zales, record studio',
  //     },
  //     {
  //       name: 'Other venues',
  //       id: 'tuv_citas',
  //       apraksts: 'dažādas telpas',
  //     },
  //   ],
  // },
  {
    name: '🎤 Audio and video equipment',
    id: 'auv_tehnika',
    subcategories: [
      {
        name: 'Audio equipment',
        id: 'auv_audio',
        apraksts: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
        keywords: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
      },
      {
        name: 'Live streaming services',
        id: 'auv_risinājumi',
        apraksts: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
        keywords: 'tumbas austiņas mikrofoni dj pultis studiju aprīkojums ierakstu tehnika',
      },
      {
        name: 'DJ mixers',
        id: 'auv_dj_mixers',
        apraksts: '',
      },

      {
        name: 'Sound equipment sets',
        id: 'auv_sound_equipment_sets',
        apraksts: '',
      },

      {
        name: 'LED screens',
        id: 'auv_led_screens',
        apraksts: '',
      },

      {
        name: 'TV screens',
        id: 'auv_tv_screens',
        apraksts: '',
      },

      {
        name: 'Projectors and screens',
        id: 'auv_projectors_screens',
        apraksts: '',
      },

      {
        name: 'Photo and video equipment',
        id: 'auv_photo_video_equipment',
        apraksts: '',
      },

      {
        name: 'Other',
        id: 'auv_other_equipment',
        apraksts: '',
        other: true,
      },
      //
      // {
      //   name: 'Foto and video equipment',
      //   id: 'auv_fotovideo',
      //   apraksts: 'kameras videokameras fotokameras filmēšanas laukumi stendi gaismas kastes',
      // },
      // {
      //   name: 'Concert audio/video equipment',
      //   id: 'auv_konc_aparatura',
      //   apraksts: 'skaļruņi pastiprinātāji vadi',
      // },
      // {
      //   name: 'Screens and projectors',
      //   id: 'auv_ekrani',
      //   apraksts: 'led ekrāni',
      // },
      // {
      //   name: 'Communication technologies',
      //   id: 'auv_sakari',
      //   apraksts: 'rācijas komunikācija',
      // },
    ],
  },
  {
    name: '⛩ Decorations',
    id: 'mui_decorations',
    subcategories: [
      {
        name: 'Decoration sets',
        id: 'mui_decoration_sets',
        apraksts: 'Decoration',
      },

      {
        name: 'Decorative elements',
        id: 'mui_decorative_elements',
        apraksts: 'Decoration',
      },

      {
        name: 'Decorations of different themes',
        id: 'mui_decorations_of_different_themes',
        apraksts: 'Decoration',
      },

      {
        name: 'Photo walls',
        id: 'mui_photo_walls',
        apraksts: 'Decoration',
      },

      {
        name: 'Table decorations',
        id: 'mui_table_decorations',
        apraksts: 'Decoration',
      },

      {
        name: 'Arches',
        id: 'mui_arches',
        apraksts: 'Decoration',
      },

      {
        name: 'Plants and floristic decorations',
        id: 'mui_plants_and_floristic_decorations',
        apraksts: 'Decoration',
      },

      {
        name: 'Artificial plants',
        id: 'mui_artificial_plants',
        apraksts: 'Decoration',
      },

      {
        name: 'Other',
        id: 'mui_other_decoration',
        apraksts: 'Decoration',
        other: true,
      },

    ],
  },
  {
    name: '🍽 Catering',
    id: 'mui_catering',
    subcategories: [
      {
        name: 'Banquet services',
        id: 'pserv_banquet_services',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Food trucks, mobile catering',
        id: 'pserv_food_trucks_mobile_catering',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Waiters and bartenders',
        id: 'pserv_waiters_and_bartenders',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Dishes and tableware',
        id: 'pserv_dishes_and_tableware',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Refrigerators and freezers',
        id: 'pserv_refrigerators_and_freezers',
        apraksts: 'Ēdināšana',
      },

      {
        name: 'Other',
        id: 'pserv_other_catering',
        apraksts: 'Ēdināšana',
        other: true,
      },

    ],
  },
  {
    name: '💡 Lighting',
    id: 'gus_main',
    subcategories: [
      {
        name: 'Lighting sets',
        id: 'gus_lampinas',
        apraksts: 'Apraksts',
      },

      {
        name: 'Light objects',
        id: 'gus_light_objects',
        apraksts: 'Apraksts',
      },

      {
        name: 'Decorative lighting',
        id: 'gus_decorative_lighting',
        apraksts: 'Apraksts',
      },

      {
        name: 'Chain lights',
        id: 'gus_chain_lights',
        apraksts: 'Apraksts',
      },

      {
        name: 'Other',
        id: 'gus_other_lights',
        apraksts: 'Apraksts',
        other: true,
      },

      //
      // {
      //   name: 'Indoor lighting',
      //   id: 'gus_lampas_griestu',
      //   apraksts: 'Griestu lampas, stāvlampas, stavlampas, galda lampas, istabas',
      // },
      //
      // {
      //   name: 'Spotlights',
      //   id: 'gus_prozektori',
      //   apraksts: 'prozektori',
      // },
      //
      // {
      //   name: 'LED objects',
      //   id: 'gus_led',
      //   apraksts: 'Apraksts',
      // },
      //
      // {
      //   name: 'Non-standart lighting',
      //   id: 'gus_nest_gaismas',
      //   apraksts: 'visadas divainas gaismas, visādas dīvainas gaismas, interesantas',
      // },

    ],
  },
  {
    name: '🚏 Info stands and signs',
    id: 'stands',
    subcategories: [
      {
        name: 'Info stands',
        id: 'info_stands',
        apraksts: '',
      },

      {
        name: 'Banner stands and frames',
        id: 'banner_stands_frames',
        apraksts: '',
      },

      {
        name: 'Flagpoles',
        id: 'flagpoles',
        apraksts: '',
      },

      {
        name: 'Signs',
        id: 'signs',
        apraksts: '',
      },

      {
        name: 'Other',
        id: 'other_signs',
        apraksts: '',
        other: true,
      },

    ],
  },
  {
    name: '🛋 Furniture',
    id: 'mui_mebeles',
    subcategories: [
      {
        name: 'Tables and counters',
        id: 'galdi',
        apraksts: '',
      },
      {
        name: 'Chairs',
        id: 'kresli',
        apraksts: '',
      },
      {
        name: 'Sofas and bean bags',
        id: 'divani',
        apraksts: '',
      },
      {
        name: 'Podiums',
        id: 'podiums',
        apraksts: '',
      },

      {
        name: 'LED furniture',
        id: 'led_mebeles',
        apraksts: '',
      },

      {
        name: 'Mirrors',
        id: 'mirrors',
        apraksts: '',
      },

      {
        name: 'Stands',
        id: 'stativi',
        apraksts: 'sienas, žogi, zogi',
      },

      {
        name: 'Shelves, closets, drawers',
        id: 'skapji',
        apraksts: '',
      },

      {
        name: 'Outdoor furniture',
        id: 'mui_skapji',
        apraksts: 'Apraksts',
      },

      {
        name: 'Other',
        id: 'mui_other_furniture',
        apraksts: 'Apraksts',
        other: true,
      },

      // {
      //   name: 'Divider screens',
      //   id: 'mui_aizslietni',
      //   apraksts: 'Apraksts',
      // },
      // {
      //   name: 'Storage boxes',
      //   id: 'mui_kastes',
      //   apraksts: 'uzglabasana',
      // },
      //
      // {
      //   name: 'Clothing racks',
      //   id: 'mui_garderobes',
      //   apraksts: 'Apraksts',
      // },
    ],
  },

  {
    name: '🌳 Event equipment',
    id: 'tut_event_equipment',
    subcategories: [
      {
        name: 'Heaters',
        id: 'gus_silditaji',
        apraksts: 'Radiatori',
      },

      {
        name: 'Air fans',
        id: 'gus_fans',
        apraksts: '',
      },

      {
        name: 'Sanitary equipment',
        id: 'aaut_sanitarais',
        apraksts: 'santehnika, tualetes, podi, izlietnes, udens, ūdens',
      },

      {
        name: 'Electrical installations',
        id: 'aaut_elektro',
        apraksts: 'eletriba',
      },

      {
        name: 'Fences',
        id: 'aaut_zogi',
        apraksts: 'Apraksts',
      },

      {
        name: 'Communication equipment',
        id: 'aaut_communication_equipment',
        apraksts: '',
      },

      {
        name: 'Computers, smartphones, tablets',
        id: 'aaut_computers_smartphones_tablets',
        apraksts: '',
      },

      {
        name: 'Charging tables',
        id: 'aaut_charging_tablets',
        apraksts: '',
      },

      {
        name: 'Other',
        id: 'aaut_event_equipment',
        apraksts: '',
        other: true,
      },

    ],
  },




  {
    name: '🎉 Event content',
    id: 'mui_event_content',
    subcategories: [
      {
        name: 'Show and event hosts',
        id: 'pserv_vaditaji',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },
      {
        name: 'Saunas and hot tubes',
        id: 'up_to_50_people',
        apraksts: '',
      },
      {
        name: 'Musicians',
        id: 'pserv_musicians',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'DJ',
        id: 'pserv_dj',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'Performing artists',
        id: 'pserv_performing_artists',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'Acitivities and games',
        id: 'pserv_acitivities_and_games',
        apraksts: 'cirka šovi, bārmeņu šovi',
      },

      {
        name: 'Other',
        id: 'pserv_Other',
        apraksts: 'cirka šovi, bārmeņu šovi',
        other: true,
      },
    ],
  },
  {
    name: '🦸🏻 Professional services',
    id: 'pserv_main',
    subcategories: [

      {
        name: 'Photographers',
        id: 'pserv_fotografi',
        apraksts: 'VJ, video operatori, video animatori',
      },
      {
        name: 'Assembly services',
        id: 'montāžas_pakalpojumi',
        apraksts: 'VJ, video operatori, video animatori',
      },
      {
        name: 'Video producers',
        id: 'pserv_video_producers',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Make-up artists, stylists, hairstylists',
        id: 'pserv_vizazisti',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Designers and scenographers',
        id: 'pserv_designers_and_scenographers',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Screenwriters',
        id: 'pserv_seenwriters',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Light and sound engineers',
        id: 'pserv_light_and_sound_engineers',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Event staff',
        id: 'pserv_event_staffs',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Text correctors and translators',
        id: 'pserv_text_correctors_and_translators',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Cleaning services',
        id: 'pserv_cleaning_services',
        apraksts: 'VJ, video operatori, video animatori',
      },

      {
        name: 'Other',
        id: 'pserv_other',
        apraksts: 'VJ, video operatori, video animatori',
        other: true,
      },

      //
      // {
      //   name: 'Designers and programmers',
      //   id: 'pserv_programmesana',
      //   apraksts: 'dizains, maketēšana, dagnis, edmunds',
      // },
      // {
      //   name: 'Performers, musicians',
      //   id: 'pserv_muziki',
      //   apraksts:
      //     'Dziedātāji, izpildītāji, dziedataji, izpilditaji, grupas, bunģieri, ģitāristi, vijolisti, klavieru spēlētāji',
      // },
      //

      // {
      //   name: 'Event managers',
      //   id: 'pserv_organizatori',
      //   apraksts: 'freelanceri, menedzeri, organizetaji, andis',
      // },
      // {
      //   name: 'Promoters',
      //   id: 'pserv_promoteri',
      //   apraksts: 'reklāmisti, reklamas specialisti, reklāmas speciālisti',
      // },
      // {
      //   name: 'Event technicians',
      //   id: 'pserv_tehniskie',
      //   apraksts: 'Strādnieki, stradnieki, elektriki',
      // },
      //
      // {
      //   name: 'Game and activity planners',
      //   id: 'pserv_aktivitates',
      //   apraksts: 'speles un aktivitates',
      // },
      // {
      //   name: 'Light engineer',
      //   id: 'pserv_gaismotaji',
      //   apraksts: 'gaismas, prozektori',
      // },
      // {
      //   name: 'Sound engineer',
      //   id: 'pserv_skanotaji',
      //   apraksts: 'skanotaji, didzeji, dīdžeji',
      // },
      // {
      //   name: 'Chefs, waiters, bartenders',
      //   id: 'pserv_pavari',
      //   apraksts: 'pavari, viesmili, barmeni, virtuves darbinieki',
      // },
      // {
      //   name: 'Scenographers and artists',
      //   id: 'pserv_scenografi',
      //   apraksts: 'Skatuvju iekārtotāji, dagnis',
      // },
      // {
      //   name: 'Translators and script writers',
      //   id: 'pserv_tulki',
      //   apraksts: 'Rakstnieki, scenaristi, dzejnieki, tekstu autori',
      // },

    ],
  },
  {
    name: '⛺️ Tents and shelters',
    id: 'tun_teltis',
    subcategories: [
      {
        name: 'Large tents',
        id: 'tun_lielas_teltis',
        apraksts: 'Apraksts',
      },
      {
        name: 'Medium and small tents',
        id: 'tun_mazas-lielas-teltis',
        apraksts: 'Caurspidigas, caurspīdīgas',
      },
      {
        name: 'Tent cornices and banner frames',
        id: 'tun_tent_cornices_banner_frames',
        apraksts: '',
      },
      {
        name: 'Non-standart shelters',
        id: 'tun_non_standart_shelters',
        apraksts: '',
      },
      {
        name: 'Tent flooring',
        id: 'tun_tent_flooring',
        apraksts: '',
      },

      {
        name: 'Other',
        id: 'tun_other_shelters',
        apraksts: '',
        other: true,
      },
    ],
  },
  {
    name: '🏩 Event venues',
    id: 'event_venues',
    subcategories: [
      {
        name: 'Up to 50 people',
        id: 'up_to_50_people',
        apraksts: '',
      },
      {
        name: 'Up to 150 people',
        id: 'up_to_150_people',
        apraksts: '',
      },

      {
        name: 'Up to 400 people',
        id: 'up_to_400_people',
        apraksts: '',
      },

      {
        name: 'Over 400 people',
        id: 'over_400_people',
        apraksts: '',
      },

      {
        name: 'Outdoor event areas',
        id: 'outdoor_event_areas',
        apraksts: '',
      },

      {
        name: 'Rehearsal rooms',
        id: 'rehearsal_rooms',
        apraksts: '',
      },

      {
        name: 'Other',
        id: 'other_event_venues',
        apraksts: '',
        other: true,
      },



    ],
  },
  {
    name: '🎪 Stages',
    id: 'mui_stages',
    subcategories: [
      {
        name: 'Stage constructions',
        id: 'mui_stage_constructions',
        apraksts: 'Stage',
      },

      {
        name: 'Full stage set',
        id: 'mui_full_stage_set',
        apraksts: 'Stage',
      },

      {
        name: 'Stage decks',
        id: 'mui_stage_decks',
        apraksts: 'Stage',
      },

      {
        name: 'Other',
        id: 'mui_other',
        apraksts: 'Stage',
        other: true,
      },

    ],
  },
  {
    name: '🚐 Transport',
    id: 'tut_transport',
    subcategories: [
      {
        name: 'Cargo services',
        id: 'tut_cargo',
        apraksts: '',
      },

      {
        name: 'Passenger transport',
        id: 'tut_passenger_transport',
        apraksts: '',
      },

      {
        name: 'Cars and themed vehicles',
        id: 'tut_cars_themed_vehicles',
        apraksts: '',
      },

      {
        name: 'Trailers',
        id: 'tut_trailers',
        apraksts: '',
      },

      {
        name: 'Heavy construction equipment',
        id: 'tut_heavy_construction_equipment',
        apraksts: '',
      },

      {
        name: 'Other',
        id: 'tut_other_transport',
        apraksts: '',
        other: true,
      },

    ],
  },
  {
    name: '👗 Textile and costumes',
    id: 'tut_terpi',
    subcategories: [
      {
        name: 'Table covers',
        id: 'tut_galdauti',
        apraksts: 'Šīs ir tiešām lielas kleitas',
      },
      {
        name: 'Chair covers',
        id: 'tut_parvalki',
        apraksts: 'Šīs ir tiešām mazas kleitas',
      },

      {
        name: 'Drapery and curtains',
        id: 'tut_drapery_curtains',
        apraksts: '',
      },

      {
        name: 'Carpets',
        id: 'tut_paklaji',
        apraksts: 'Šīs ir tiešām īpašas kleitas',
      },

      {
        name: 'Costume rent',
        id: 'tut_terpu_noma',
        apraksts:
          'Bikses, krekli, drēbes, drebes, kleitas, uzvalki, smokingi, šlipses, kurpes, zābaki, kostīmi',
      },

      {
        name: 'Tailors',
        id: 'tut_tailora',
        apraksts: '',
      },

      {
        name: 'Other',
        id: 'tut_other_textile',
        apraksts: '',
        other: true,
      },

      //
      // {
      //   name: 'Chair cushions',
      //   id: 'tut_spilveni',
      //   apraksts: 'Šīs ir tiešām īpašas kleitas',
      // },
      // {
      //   name: 'Decorative fabrics',
      //   id: 'tut_audumi',
      //   apraksts: 'Šīs ir tiešām citas kleitas',
      // },
      // {
      //   name: 'Drapery',
      //   id: 'tut_draperijas',
      //   apraksts: 'Šīs ir tiešām lielas kleitas',
      // },
      // {
      //   name: 'Curtains',
      //   id: 'tut_aizkari',
      //   apraksts: 'Šīs ir tiešām mazas kleitas',
      // },
      //
      // {
      //   name: 'Napkins',
      //   id: 'tut_salvetes',
      //   apraksts: 'Šīs ir tiešām citas kleitas',
      // },
      // {
      //   name: 'Flag strings/Bunting',
      //   id: 'tut_karodzini',
      //   apraksts: 'Šīs ir tiešām īpašas kleitas',
      // },

    ],
  },

  // {
  //   name: '⛩ Constructions and decors',
  //   id: 'suk_konstrukcijas',
  //   subcategories: [
  //     {
  //       name: 'Large format constructions',
  //       id: 'suk_lielas_konstrukcijas',
  //       apraksts: 'skatuves, skatuvju',
  //     },
  //     {
  //       name: 'Arches and entrances',
  //       id: 'suk_arkas',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Wall and ceiling decorations',
  //       id: 'suk_dekori',
  //       apraksts: 'sienas, griesti, gleznas',
  //     },
  //     {
  //       name: 'Decorations of different themes',
  //       id: 'suk_tem_dekori',
  //       apraksts: 'Dekori īpašām tēmām',
  //     },
  //     {
  //       name: 'Decorative objects',
  //       id: 'suk_dek_objekti',
  //       apraksts: 'dekoraciajs',
  //     },
  //     {
  //       name: 'Floristic decorations',
  //       id: 'suk_ziedu_dek',
  //       apraksts: 'ziedi, rozes, tulpes',
  //     },
  //   ],
  // },
  // {
  //   name: '🚐 Vechiles and trucks',
  //   id: 'daust_smaga-tehnika',
  //   subcategories: [
  //     {
  //       name: 'Truck',
  //       id: 'daust_kravas',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Regular vechile',
  //       id: 'daust_vieglas_masinas',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Non-standart vechile',
  //       id: 'daust_nestandarta_auto',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Electrical instruments',
  //       id: 'daust_elektroinstrumenti',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Heavy construction equipment',
  //       id: 'daust_smagie',
  //       apraksts: 'Apraksts',
  //     },
  //   ],
  // },
  // {
  //   name: '🌳 Outdoor equipment',
  //   id: 'aaut_araaprikojums',
  //   subcategories: [
  //     {
  //       name: 'Outdoor heaters',
  //       id: 'aaut_ara_silditaji',
  //       apraksts: 'siltums',
  //     },
  //
  //
  //
  //     {
  //       name: 'Outdoor furniture',
  //       id: 'aaut_ara_mebeles',
  //       apraksts: 'Ara kresli, āra krēsli, galdi, gultas, krēsliņi',
  //     },
  //   ],
  // },
  // {
  //   name: '🚏 Advertising stands and signs',
  //   id: 'rsun_brendings',
  //   subcategories: [
  //     {
  //       name: 'Stands',
  //       id: 'rsun_stendi',
  //       apraksts: 'Šīs ir tiešām lielas norādes',
  //     },
  //     {
  //       name: 'Banner stands and frames',
  //       id: 'rsun_baneri',
  //       apraksts: 'Šīs ir tiešām lielas norādes',
  //     },
  //     {
  //       name: 'Signs and other...',
  //       id: 'rsun_norades',
  //       apraksts: 'Šīs ir tiešām mazas norādes',
  //     },
  //   ],
  // },
  // {
  //   name: '🍽 Cutlery and decorations',
  //   id: 'gpud_galda_dekori',
  //   subcategories: [
  //     {
  //       name: 'Tableware',
  //       id: 'gpud_trauki',
  //       apraksts:
  //         'glāzes, šķīvji, dakšiņas, naži, karotes, bļodas, krūzes, glazes, skivji, daksinas, nazi, blodas, kruzes',
  //     },
  //     {
  //       name: 'Vases',
  //       id: 'gpud_vazes',
  //       apraksts: 'vazes, kruzes, puku, puķu podi',
  //     },
  //     {
  //       name: 'Table decorations',
  //       id: 'gpud_gald_dek',
  //       apraksts: 'dekoracijas, dekorācijas, dekorējumi',
  //     },
  //     {
  //       name: 'Food stands',
  //       id: 'gpud_ed_stendi',
  //       apraksts: 'sokolades, fonteini, kūkas, kūku',
  //     },
  //     {
  //       name: 'Indoor plant rent',
  //       id: 'gpud_augu_noma',
  //       apraksts: 'augi',
  //     },
  //   ],
  // },
  // {
  //   name: '🏗 Manufacturers, Polygraphy agencies, builders',
  //   id: 'rdb_main',
  //   subcategories: [
  //     {
  //       name: 'Manufacturing',
  //       id: 'rdb_konstrukciju_razotaij',
  //       apraksts: 'Apraksts',
  //     },
  //     {
  //       name: 'Printing and pasting/application',
  //       id: 'rdb_drukataji',
  //       apraksts: 'Baneri, reklāmas, uzlīmes, flaijeri',
  //     },
  //     {
  //       name: 'Carpentry and metalwork',
  //       id: 'rdb_galdnieki',
  //       apraksts: 'Metālstrādnieki, metalstradnieki',
  //     },
  //     {
  //       name: 'Construction workers',
  //       id: 'rdb_buvdarbu',
  //       apraksts: 'Izgatavotaji',
  //     },
  //     {
  //       name: 'Transporters and loaders',
  //       id: 'rdb_transports',
  //       apraksts: 'kravataji, parvadataji, pārvadātāji',
  //     },
  //   ],
  // },
  // {
  //   name: '🎸 Musical instruments',
  //   id: 'mi_instrumenti',
  //   subcategories: [
  //     {
  //       name: 'Keyboard instruments',
  //       id: 'mi_taustini',
  //       apraksts: 'Klavieres, sintezatori, taustininstrumenti, piano',
  //     },
  //     {
  //       name: 'String instruments',
  //       id: 'mi_stigu',
  //       apraksts: 'Ģitāras, gitaras, vijoles, kontrabasi, celli, čelli',
  //     },
  //     {
  //       name: 'Percussion instruments',
  //       id: 'mi_sitamie',
  //       apraksts: 'Bungas, klavesīni, skivji, šķīvji',
  //     },
  //     {
  //       name: 'Wind instruments',
  //       id: 'mi_pusamie',
  //       apraksts: 'Taures, tromboni, flautas, mežragi, saksafoni',
  //     },
  //     {
  //       name: 'Other instruments',
  //       id: 'mi_citi_instrumenti',
  //       apraksts: 'Visādi instrumenti',
  //     },
  //   ],
  // },
];

