import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/CategoriesPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/CategoriesPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/CategoriesPage/SEARCH_LISTINGS_ERROR';


export const GET_TOTAL_PAGES_REQUEST = 'app/CategoriesPage/GET_TOTAL_PAGES_REQUEST';
export const GET_TOTAL_PAGES_SUCCESS = 'app/CategoriesPage/GET_TOTAL_PAGES_SUCCESS';
export const GET_TOTAL_PAGES_ERROR = 'app/CategoriesPage/GET_TOTAL_PAGES_ERROR';




// ================ Reducer ================ //

const initialState = {
  pagination: null,
  totalPages: 1,
  getTotalPagesInProgress: false,
  getTotalPagesSuccess: false,
  getTotalPagesError: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const categoriesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: [...state.currentPageResultIds, ...resultIds(payload.data)],
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };


    case GET_TOTAL_PAGES_REQUEST:
      return {
        ...state,
        getTotalPagesInProgress: true,
        getTotalPagesSuccess: false,
        getTotalPagesError: null,
      };
    case GET_TOTAL_PAGES_SUCCESS:
      return {
        ...state,
        totalPages: payload,
        getTotalPagesInProgress: false,
        getTotalPagesSuccess: true,
        getTotalPagesError: null,
      };
    case GET_TOTAL_PAGES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        getTotalPagesInProgress: true,
        getTotalPagesSuccess: false,
        getTotalPagesError: payload,
      };

    default:
      return state;
  }
};

export default categoriesPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({ type: SEARCH_LISTINGS_REQUEST, payload: { searchParams }});
export const searchListingsSuccess = response => ({ type: SEARCH_LISTINGS_SUCCESS, payload: { data: response.data }});
export const searchListingsError = e => ({ type: SEARCH_LISTINGS_ERROR, error: true, payload: e });

export const getTotalPagesRequest = () => ({ type: GET_TOTAL_PAGES_REQUEST});
export const getTotalPagesSuccess = page => ({ type: GET_TOTAL_PAGES_SUCCESS, payload:  page });
export const getTotalPagesError = e => ({ type: GET_TOTAL_PAGES_ERROR, error: true, payload: e });

export const getTotalPagesForCategories = (searchParams) => (dispatch, getState, sdk) => {
  dispatch(getTotalPagesRequest());

  const { perPage, price, dates, ...rest } = searchParams;

  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const totalPages = response.data.meta.totalPages

      dispatch(getTotalPagesSuccess(totalPages));
      return response;
    }).then((res) => {

      const totalPages = getState().CategoriesPage.totalPages;

      for (let step = 1; step <= totalPages; step++) {
        searchParams.page = step;
        dispatch(searchListings(searchParams))
      }
    })
    .catch(e => {
      dispatch(getTotalPagesError(storableError(e)));
      throw e;
    });

}


export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const { perPage, price, dates, ...rest } = searchParams;

  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};


export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, address, origin, ...rest } = queryParams;
  return getTotalPagesForCategories({
    ...rest,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};
