import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfServiceContentEn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <nav>
        <ul>
          <li>
            <a href="#accountTerms">Account Terms</a>
          </li>
          <li>
            <a href="#provisionRentalServices">Provision of Rental Services between the User and the Provider</a>
          </li>
          <li>
            <a href="#logistics">Logistics</a>
          </li>
          <li>
            <a href="#returns">Returns</a>
          </li>
          <li>
            <a href="#errorOfProvider">Error of the Provider</a>
          </li>
          <li>
            <a href="#restrictionsRegarding">Restrictions regarding use of the Items by the Users</a>
          </li>
          <li>
            <a href="#restrictionsToUse">Restrictions to use Eventify for the Providers</a>
          </li>
          <li>
            <a href="#platformFees">Platform Fees</a>
          </li>
          <li>
            <a href="#paymentsAndPaymentTerms">Payments and Payment Terms between the Provider and the User</a>
          </li>
          <li>
            <a href="#anyPayment">Any Payment (including Fees) Handling</a>
          </li>
          <li>
            <a href="#thirdPartyIntegrations">Third Party Integrations and Functionalities</a>
          </li>
          <li>
            <a href="#liabilityOfUsers">Liability of Users</a>
          </li>
          <li>
            <a href="#claims">Claims</a>
          </li>
          <li>
            <a href="#bestConduct">Best Conduct</a>
          </li>
          <li>
            <a href="#limitationsOnLiability">Limitations on Liability</a>
          </li>
          <li>
            <a href="#disclaimerOnWarranties">Disclaimer on Warranties</a>
          </li>
          <li>
            <a href="#privacyAndDataProtection">Privacy and Data Protection</a>
          </li>
          <li>
            <a href="#confidentiality">Confidentiality</a>
          </li>
          <li>
            <a href="#intellectualPropertyContent">Intellectual Property and your Content</a>
          </li>
          <li>
            <a href="#eventifyIntellectualProperty">Eventify Intellectual Property</a>
          </li>
          <li>
            <a href="#termAndTermination">Term and Termination</a>
          </li>
          <li>
            <a href="#finalProvisions">Final Provisions</a>
          </li>

        </ul>
      </nav>

      <p>
        We are SIA EventHub, a limited liability company, founded and registered in the Republic of Latvia (company registration code: 40203046104),
        whose places of businesses is at Rūpniecības iela 19-3, Rīga, LV-1010, Latvia, hereinafter <b>“we”</b>, <b>“us”</b>, <b>“our”</b> or <b>“Eventify”</b>.
        If any other Eventify group companies are created which may impact your rights and obligations under these Terms of Service (<b>“Terms of Service”</b>),
        they shall be mentioned here.
      </p>

      <p>
        We are operating and providing services through the website <a href="http://www.eventify.lv/" target="_blank">www.eventify.lv</a> and its subdomains (<b>“Website”</b>) and Eventify solution,
        including the software, databases, interfaces, associated media, documentation, updates, new releases and other components or
        materials incorporated therein or integrated therewith (hereinafter collectively the <b>“Platform”</b>).
      </p>

      <p>
        Through the Platform, we provide a circular economy solution that enables interested parties (<b>“Members”</b> or <b>“you”</b>, <b>“your”</b>)
        to publish, offer, search for and order goods and/or services (<b>“Items”</b>) of other Members online.
      </p>

      <p id="accountTerms">
        Eventify as the proprietor of the Platform does not own, control, offer or manage any of the Items of the Members,
        but acts as an intermediary between the Members. Eventify cannot be considered a retail or rental business, and
        cannot be held liable as such. The Eventify platform does not provide logistics or delivery services between Members.
      </p>

      <h2>
        1. Account Terms
      </h2>

      <ol>
        <li>
          <span>1.1</span>
          To access and use the Platform, all Members must register for an Eventify account (<b>“Account”</b>). Members may register either as legal
          persons or natural persons. To complete your Account registration, you must provide us with a valid email address.
          Eventify may reject your application for an Account, or cancel an existing Account, for any reason, at our sole discretion.
        </li>
        <li>
          <span>1.2</span>
          <b>Users</b> are Members who search and order Items. Member becomes a User when placing a respective order of Items.
          To make an order the User shall have to provide the Platform and the Provider with additional information,
          including, but not limited with - delivery information, contact details, and the approximate time of delivery and returns.
        </li>
        <li id="provisionRentalServices">
          <span>1.3</span>
          <b>Providers</b> are Members who publish and offer Items. Providers offer Items for rent to Users as a form of short-term rental.
          Member becomes a Provider when submits an announcement about respective Items available for short-term rent.
        </li>
      </ol>

      <h2>
        2. Provision of Rental Services between the User and the Provider
      </h2>

      <ol>
        <li>
          <span>2.1</span>
          A commercial agreement with provision of rental services according to laws of the Republic of Latvia is made between the
          User and the Provider at the moment when (i) the User places an order for the Items of the Provider and (ii) the
          Provider accepts the respective order. The rental service agreement made between the User and the Provider shall
          contain relevant terms of these Terms of Service without additional written agreement, if parties decide otherwise
          they shall incorporate terms of these Terms of Service within their rental service agreement. For the avoidance of doubt,
          Eventify will not be a party to any commercial or rental service agreements made between the Users and the Providers.
        </li>
        <li>
          <span>2.2</span>
          Disputes arising from consumer rights, legal obligations or from laws and regulations that may be applicable to the provision
          of rental services are to be resolved between the Users and the Providers, though Eventify will provide mediation where
          appropriate through our customer success unit.

        </li>
        <li>
          <span>2.3</span>
          Any payments for the provision of rental services shall be made through the options
          available to the User on the Eventify Platform.
        </li>
        <li id="logistics">
          <span>2.4</span>
          Members of the Eventify Platform may include trademarks, copyright content, any Items (including description and price),
          and any photos, images, videos, graphics, written content, audio files, code, information, or other
          data provided or made available by Members to Eventify or its affiliates (<b>“Content”</b>), to allow Members of
          the Eventify Platform to facilitate interactions with other Members of the Eventify Platform.
        </li>
      </ol>

      <h2>
        3. Logistics
      </h2>

      <ol>
        <li id="returns">
          <span>3.1</span>
          The Eventify Platform does not provide delivery services for the listed Items of the Providers.
          Logistics should be handled between Providers and Users directly without Eventify Platform interference.
        </li>
      </ol>

      <h2>
        4. Returns
      </h2>
      <ol>
        <li>
          <span>4.1</span>
          Users are liable for returning rented Items of the Provider back to the Providers in form and shape as
          provided to the Users. The Users must use the same packaging for returning the Items as they were
          delivered with. The User must not in any way make any cosmetic or physical changes to the
          packaging or the Items except if otherwise instructed by the Provider and owner of
          the Items. The User is liable for any major damages to the packaging and/or the Items
          of the Provider made during the term of the rental.
        </li>
        <li id="errorOfProvider">
          <span>4.2</span>
          If unsure about any of the instructions on how to prepare Items for returns, please contact Eventify
          via phone for a step-by-step guide through the process.
        </li>
      </ol>

      <h2>
        5. Error of the Provider
      </h2>
      <ol>
        <li>
          <span>5.1</span>
          If the Item rented out by the User from the Provider is the wrong Item, does not fit the description in
          the Eventify Platform, has missing elements, sub-items, or otherwise does not fit the purpose or
          function to the extent that could be reasonably expected the Item to fulfill, the User can submit a
          claim to Eventify describing what specifically was wrong with the order.
        </li>
        <li id="restrictionsRegarding">
          <span>5.2</span>
          If Eventify identifies that some Items were wrong or missing in the order made by the User, then the Provider
          will be obliged to provide a very similar or exact same Item or cart of Items not later than the next day.
          If that is not possible, Eventify will reimburse the value order as soon as feasible.
        </li>
      </ol>

      <h2>
        6. Restrictions regarding use of the Items by the Users
      </h2>
      <p>
        When using the Items rented via Eventify Platform, the User undertakes:
      </p>
      <ol>
        <li>
          <span>6.1</span>
          to ensure that the activities User uses the Item for are compliant with applicable local laws and regulatory requirements;
        </li>
        <li>
          <span>6.2</span>
          where needed and when needed confirm legal age of the User and/or provide a valid license or other proof to be able to use Item;
        </li>
        <li>
          <span>6.3</span>
          to read and comply with the terms and conditions of Items provided by the Provider, if any, and comply with the Terms of Service of the Eventify Platform;
        </li>
        <li>
          <span>6.4</span>
          to provide true and accurate information within the Eventify Platform;
        </li>
        <li id="restrictionsToUse">
          <span>6.5</span>
          to cover any losses made to the Provider in case the Item while used by the User is lost or damaged.
        </li>
      </ol>

      <h2>
        7. Restrictions to use Eventify for the Providers
      </h2>
      <p>
        When providing the Items available via Eventify Platform, the Provider undertakes:
      </p>

      <ol>
        <li>
          <span>7.1</span>
          not to charge the User for any additional costs or fees relating to the Items other than those submitted via
          Eventify Platform and specified in the Provider's terms and conditions, if any;
        </li>
        <li>
          <span>7.2</span>
          not to use the Eventify Platform for any transactions relating to Items that are illegal, inappropriate or dangerous,
          including but not limited to weapons, toxic, flammable or hazardous substances, drugs or pharmaceutical goods,
          living animals, adult entertainment of any kind, gambling or lottery Items, financial goods and services,
          counterfeit goods or other similar Items;
        </li>
        <li>
          <span>7.3</span>
          to ensure that the rental of items involving copyrighted or other IPR content does not infringe any third
          party rights or local legislation, including but not limited to copyright or trademark legislation;
        </li>
        <li>
          <span>7.4</span>
          where the use of an Item requires a valid license or is subject to legal age requirements, to confirm the
          existence and validity of such license or confirm the age of the User prior to handing over the Item to the User;
        </li>
        <li>
          <span>7.5</span>
          to ensure that the User is provided with adequate instructions and possible safety equipment (e.g. helmet) for the use of the Item;
        </li>
        <li>
          <span>7.6</span>
          to be solely responsible and liable for the Items that are rented through the Eventify
          Platform (including description, price, fees, tax that Provider calculate, defects, required legal disclosures,
          regulatory compliance, offers or promotional content), including compliance with any applicable laws or regulations;
        </li>
        <li>
          <span>7.7</span>
          not to use the Eventify Platform for any illegal or unauthorized purpose nor may Provider, in the use of the
          Eventify Platform, violate any laws (including but not limited to copyright laws), the laws applicable to Provider
          in the User's jurisdiction, or the laws of Republic of Latvia. To comply with all applicable laws, rules and
          regulations (including but not limited to obtaining and complying with the requirements of any license or permit that
          may be necessary to operate Provider's business or business that may be held by Provider) in Provider's use of the
          Eventify Platform and Provider's performance of obligations under the Terms of Service.
        </li>
        <li>
          <span>7.8</span>
          to acknowledge and agree that any contract of rent made through the Eventify Platform is directly between
          Provider and the User. Provider is the lessor of record for all Items rent through the Eventify Platform.
          You are responsible for the creation and operation of Provider's Account, any Content provided, the Items rented
          through the Eventify Platform, and all aspects of the transactions between Provider and User. This includes, but is
          not limited to, authorizing the charge to the User in respect of therented Item, refunds, returns, fulfilling any
          customer service, if any, fraudulent transactions, required legal disclosures (conclusion of distance agreement, withdrawal
          rights for consumers, if any), regulatory compliance, alleged or actual violation of applicable laws (including but not limited to
          consumer protection laws in any jurisdiction where Items are offered for rent), or Provider's breach of these Terms of Service.
          Provider represents and warrants that Items rented through Eventify Platform, any Content provided will be true, accurate,
          and complete, and will not violate any applicable laws, regulations or rights of third parties. For the avoidance of doubt,
          Eventify will not be the lessor or merchant or record and will have no responsibility for Provider's Items rented to
          the Users through the Eventify Platform.
        </li>
        <li id="platformFees">
          <span>7.9</span>
          considering that Eventify Platform does not provide insurance nor insurance services, then make sure the
          Items are insured where needed by the Provider acquiring respective insurance to cover respective risks as
          legally would be required.
        </li>
      </ol>

      <h2>
        8. Platform Fees
      </h2>
      <ol>
        <li>
          <span>8.1</span>
          Eventify shall charge transaction fees from Members of the Platform (<b>“Fees”</b>). Fees shall be charged from time to
          time at Eventify's discretion and available on Eventify Platform.
        </li>
        <li>
          <span>8.2</span>
          Eventify offers any Member of the Platform to subscribe as a Premium Member and to enjoy benefits of discounts for
          Fees, possibility to defer Fees at the moment of returning Item (only for Fees made with payment card), pay Fees in
          cash and other offers from the Eventify Platform, which may change from time to time.
        </li>
        <li>
          <span>8.3</span>
          Unless specified otherwise, all Fees are exclusive of VAT. VAT shall be added to the Fees when calculated and be borne by the Members.
        </li>
        <li>
          <span>8.4</span>
          Eventify will provide billing and related usage information in a format we choose, which may change from time to time.
          Eventify reserves the right to correct any errors or mistakes that it identifies even if it has already issued an
          invoice or received payment. Members agree to notify Eventify about any billing problems or discrepancies within
          thirty (30) days after they first appear on your invoice. If a Member does not bring such problems/discrepancies to
          Eventify attention within thirty (30) days, it agrees to waive its right to dispute such problems or discrepancies.
        </li>
        <li>
          <span>8.5</span>
          You must keep a valid payment method on file with us to pay for all incurred and recurring Fees. Eventify will charge applicable
          Fees to any valid payment method that you authorize (<b>“Authorized Payment Method”</b>), and Eventify will continue to charge the
          Authorized Payment Method for applicable Fees for each transaction made via our Platform, and any and all outstanding
          Fees have been paid in full.
        </li>
        <li>
          <span>8.6</span>
          If we are not able to process payment of Fees using an Authorized Payment Method, we will make a second attempt to process
          payment using any Authorized Payment Method, typically 14 days later, but Eventify may attempt sooner. If the second attempt
          is not successful, we will make a final attempt, typically 14 days following the second attempt, but in certain circumstances
          Eventify may do this second attempt sooner. If our final attempt is not successful, we may suspend and revoke access to your
          Account and the Platform. Your Account will be reactivated upon your payment of any outstanding Fees, plusany late payment
          fees. You may not be able to access your Account during any period of suspension. If the outstanding Fees remain unpaid for
          60 days following the date of suspension, Eventify reserves the right to terminate your Account in accordance with our
          Terms of Service.
        </li>
        <li id="paymentsAndPaymentTerms">
          <span>8.7</span>
          Eventify Platform does not provide refunds of Fees, if the User has requested a refund from the Provider. Fees paid to
          Eventify shall not be refunded in such case nor any other case.
        </li>
      </ol>

      <h2>
        9. Payments and Payment Terms between the Provider and the User
      </h2>

      <ol>
        <li>
          <span>9.1</span>
          Any payments for the Items shall take place on Platform by way of providing payment details
          (card details for Users and account number information for Providers) to settle payment or in case Premium Member
          status is activated either to the User or to the Provider in cash when receiving or delivering Item to the User.
        </li>
        <li>
          <span>9.2</span>
          Payments for Items shall be reserved from the User's payment card at the moment of booking of the Items, however
          payments (deducting Platform Fees) are sent to the bank account of the Provider within 5 (five) days after the end
          of the rental period specified on the Eventify Platform.
        </li>
        <li>
          <span>9.3</span>
          Unless specified otherwise, all payments for the Items are inclusive of any applicable local or national taxes (including VAT).
        </li>
        <li>
          <span>9.4</span>
          Provider is solely responsible for determining, collecting, withholding, reporting, and remitting applicable taxes,
          duties, fees, surcharges and additional charges that arise from or as a result of rent of Item provided via Eventify Platform.
        </li>
        <li id="anyPayment">
          <span>9.5</span>
          By use of the Eventify Platform every Member has authorized Eventify, as the case may be, as their commercial agent for
          the mediation of conclusion of contracts between the User and the Provider, including the power to accept payments from
          the User and to forward the payments to the Provider and to accept payments from the Provider and to forward the payments
          to the User. Any obligations will be fulfilled when the payment order is given to transfer funds to the respective account.
          Each Member is responsible for ensuring that the payment takes place and ensuring that sufficient funds are available.
        </li>
      </ol>

      <h2>
        10. Any Payment (including Fees) Handling
      </h2>
      <ol>
        <li>
          <span>10.1</span>
          <b>Use of third party payment platforms.</b> Use of the Eventify Platform may be subject to a third-party payment service
          provider’s acceptance of the Members provided payment card information. Please note that in addition to these Terms of Service
          between Members and Eventify, additional terms and conditions may be applicable to the direct relationship between Members
          and the payment service provider.
        </li>
        <li id="thirdPartyIntegrations">
          <span>10.2</span>
          <b>Payment security.</b> Eventify shall be responsible for the security of Member’s cardholder data to the extent it is stored, possessed
          or transmitted by Eventify in connection with Member’s use of the Eventify Platform, or to the extent Eventify can impact the
          security of the Member’s cardholder data environment. To ensure the secure processing of Member’s cardholder data, Eventify has
          engaged credible third party payment service providers.
        </li>
      </ol>

      <h2>
        11. Third Party Integrations and Functionalities
      </h2>
      <ol>
        <li>
          <span>11.1</span>
          Certain functionalities of the Eventify Platform may be provided by or integrated with services provided by third party service providers.
        </li>
        <li id="liabilityOfUsers">
          <span>11.2</span>
          For the avoidance of doubt, all third party functionalities, interfaces and integrations relating to the Eventify Platform are provided on an “as is” basis.
        </li>
      </ol>

      <h2>
        12. Liability of Users
      </h2>
      <ol>
        <li>
          <span>12.1</span>
          If the Item or the Items under the possession of the User have been damaged or lost, lowering the market value of the Item,
          then the User is liable for compensating the Provider for those damages. In such a case, the Provider shall provide an estimate
          to the User for the market value of the Item (<b>“Fair Estimate”</b>) and will charge the User to the extent of the
          Fair Estimate (<b>“Damage Claim”</b>) and provide an invoice detailing costs and calculations for that purpose.
        </li>
        <li>
          <span>12.2</span>
          The User will be obliged to pay the Damage Claim to the bank account indicated by Provider in the Damage Claim invoice no later
          than fifteen (15) days (<b>“Damage Claim Term”</b>) after the invoice has been sent out to the e-mail address indicated by the User
          in the Eventify Platform or Eventify website. If the User cannot make the Damage Claim payment within the Damage Claim Term, the User may
          request Provider to provide for a schedule of payments for a period of up to 4 months to make due on the
          Damage Claim (<b>“Payment Schedule”</b>). In this case, a penalty fee of 20% of the Damage Claim sum, which will be proportionally
          distributed for each month of payment.
        </li>
        <li id="claims">
          <span>12.3</span>
          The User is liable for any damages caused to the Items by a third-party ornatural events, with the exception of any
          events that can be considered as <i>force majeure</i> according to applicable laws & regulations.
        </li>
      </ol>

      <h2>
        13. Claims
      </h2>
      <ol>
        <li id="bestConduct">
          <span>13.1</span>
          Any claims or complaints about Eventify, the Eventify Platform, the Eventify website or any of the Members of the
          Eventify Platform are to be submitted to <a href="mailto:info@eventify.lv">info@eventify.lv</a>.
        </li>
        <li>
          <span>13.2</span>
          Eventify aims to reply within 3 working days.
        </li>
      </ol>

      <h2>
        14. Best Conduct
      </h2>
      <ol>
        <li>
          <span>14.1</span>
          All Members of the Eventify Platform are to act in good faith in their interactions and communications with other Members of the Eventify
          Platform while using the Eventify Platform or any other Eventify products. Members are obliged to truthfully and honestly provide
          information to other Members of the Eventify Platform or Eventify, if the information pertains to the fulfillment of an order or
          transaction on the Eventify Platform.
        </li>
        <li>
          <span>14.2</span>
          Users of the Eventify Platform are to handle any Items received through the Eventify Platform with reasonable care,
          and will take best efforts to inform themselves of any information or resources that have been provided on the Eventify Platform
          on how to use the Items received. If the information on the Eventify Platform has not been sufficient, the User is obliged
          to seek out additional information. However, Eventify is not liable for the lack of information provided on
          the Eventify Platform, or if any such information provided is misrepresentative of the Item, or any other
          Content that pertains to the use or care of the Item.
        </li>
        <li>
          <span>14.3</span>
          Provider will make a best effort to answer any queries concerning Items and to answer questions promptly.
        </li>
        <li>
          <span>14.4</span>
          Users are encouraged to contact Providers for any questions or uncertainties concerning the use of the Item or
          how to properly take care of the Item while it is in the possession of the Item.
        </li>
        <li id="limitationsOnLiability">
          <span>14.5</span>
          Users are forbidden from sub-renting the Items or selling them to any other parties, including other Members of the Eventify Platform.
        </li>
      </ol>

      <h2>
        15. Limitations on Liability
      </h2>
      <ol>
        <li>
          <span>15.1</span>
          Neither Eventify (including its affiliates and personnel) nor any other party
          involved in creating, producing, or delivering the Eventify Platform or any
          Content will be liable for any damages, including financial loss, loss of data or
          loss of goodwill, service interruption, computer damage or system failure or the
          cost of substitute products or services, or for any damages for personal or
          bodily injury or emotional distress arising out of or in connection with (i) these
          Terms of Service, (ii) the use of or inability to use the Eventify Platform or any
          Content, (iii) any communications, interactions or meetings you may have with
          someone you interact or meet with through, or as a result of, your use of the
          Eventify Platform, whether based on warranty, contract, contractual breach
          (including negligence), product liability or any other legal theory, and whether
          or not Eventify has been informed of the possibility of such damage, even if a
          limited remedy set out in these Terms of Service is found to have failed of its
          essential purpose. Except for our obligation to transmit payments to Providers
          under the terms of our Provider Agreement, in no event will Eventify’s
          aggregate liability for any claim or dispute arising out of or in connection with
          these Terms of Service, your interaction with any Members, or your use of or
          inability to use the Eventify Platform, any Content, or any Provider Service,
          exceed: (A) to Users, the amount you paid as a User during the 6-month period
          prior to the event giving rise to the liability, or (B) to anyone else, one hundred
          euros (EUR 100). These limitations of liability and damages are fundamental
          elements of the agreement between you and Eventify. If applicable law does
          not allow the limitations of liability set out in these Terms of Service, the above
          limitations may not apply to you.
        </li>
        <li>
          <span>15.2</span>
          Eventify is not liable for the quality of the Items that have been ordered or listed
          by a Member of the Eventify Platform, or whether they match the description
          provided as such on the Eventify Platform, nor any other elements or qualities
          pertaining to the Items that may otherwise impact the experience of a User of
          the Eventify Platform. In case a User is unsatisfied, has received the wrong
          Items, has received damage Items, or if a Provider has otherwise
          misrepresented the nature, state or quality of the Items that have been listed
          or otherwise placed on offer on the Eventify Platform, any issues or liabilities
          arising out of any such fact are to be sole and exclusive liability of the Provider
          who has provided such Items, and not the liability of Eventify.
        </li>
        <li>
          <span>15.3</span>
          Eventify makes an effort to find the best possible Members of the Eventify
          Platform in order to provide the best possible experience for all of our Members,
          but we cannot provide any guarantees or be considered liable for the
          communication, conduct or actions of Providers.
        </li>
        <li id="disclaimerOnWarranties">
          <span>15.4</span>
          In the case where a Member of the Eventify Platform believes that a member
          of the Eventify Platform has not acted according to the principles and terms of
          these Terms of Service, we ask that the Member submit a complaint to Eventify
          on that matter. However, outside of the liability, obligations and responsibilities
          that Eventify has explicitly, directly and unconditionally undertaken within these
          Terms of Service, Eventify will hold no legal responsibilities on any such
          matters other than proactively seeking to solve any complaints and removing
          any Members of the Eventify Platform who have not acted in good faith.
        </li>
      </ol>

      <h2>
        16. Disclaimer on Warranties
      </h2>
      <ol>
        <li id="privacyAndDataProtection">
          <span>16.1</span>
          We provide the Eventify Platform and any other products associated with
          Eventify “as is” without warranty of any kind and we disclaim all warranties,
          whether express or implied. For example: (i) we do not endorse or warrant the
          existence, conduct, performance, safety, quality, legality or suitability of any
          Members or Item or third party; (ii) we do not warrant the performance or noninterruption of the Eventify Platform; and (iii) we do not warrant that verification,
          identity or background checks conducted on Members (if any) will identify past
          misconduct or prevent future misconduct. Any references to a Member or Item
          being “verified” (or similar language) indicates only that the Member or Eventify
          has completed a relevant verification or identification process and noItem else.
          The disclaimers in these Terms of Service apply to the maximum extent
          permitted by law. If you have rights or warranties we cannot disclaim, the
          duration of any such required rights or warranties, will be limited to the
          maximum extent permitted by the applicable law.
        </li>
      </ol>

      <h2>
        17. Privacy and Data Protection
      </h2>
      <ol>
        <li id="confidentiality">
          <span>17.1</span>
          Eventify is firmly committed to protecting the privacy of your personal
          information. By using the Platform, you acknowledge and agree that Eventify’s
          collection, usage and disclosure of this personal information is governed by our
          Privacy Policy.
        </li>
      </ol>

      <h2>
        18. Confidentiality
      </h2>
      <ol>
        <li>
          <span>18.1</span>
          <b>“Confidential Information”</b> will include, but will not be limited to, any and all
          information associated with a party’s business and not publicly known,
          including specific business information, technical processes and formulas,
          software, customer lists, prospective customer lists, names, addresses and
          other information regarding customers and prospective customers, product
          designs, sales, costs (including any relevant processing fees), price lists, and
          other unpublished financial information, business plans and marketing data,
          and any other confidential and proprietary information, whether or not marked
          as confidential or proprietary. Eventify’s Confidential Information includes all
          information that you receive relating to us, or to the Platform, that is not known
          to the general public including information related to our security and practices.
        </li>
        <li>
          <span>18.2</span>
          Each party agrees to use the other party’s Confidential Information solely as
          necessary for performing its obligations under these Terms of Service and in
          accordance with any other obligations in these Terms of Service including this
          Section. Each party agrees that it will take all reasonable steps, at least
          substantially equivalent to the steps it takes to protect its own proprietary
          information, to prevent the duplication, disclosure or use of any such
          Confidential Information, other than (i) by or to its employees, agents and
          subcontractors who must have access to such Confidential Information to
          perform such party’s obligations hereunder, who each will treat such
          Confidential Information as provided herein, and who are each subject to
          obligations of confidentiality to such party that are at least as stringent as those
          contained herein; or (ii) as required by any law, regulation, or order of any court
          of proper jurisdiction over the parties and the subject matter contained in these
          Terms of Service, provided that, if legally permitted, the receiving party will give
          the disclosing party prompt written notice and use commercially reasonable
          efforts to ensure that such disclosure is accorded confidential treatment.
          Confidential Information will not include any information that the receiving party
          can prove: (A) was already in the public domain, or was already known by or in
          the possession of the receiving party, at the time of disclosure of such
          information; (B) is independently developed by the receiving party without use
          of or reference to the other party’s <span className={css.anchor} id="intellectualPropertyContent">Confidential Information</span>, and without
          breaching any provisions of these Terms of Service; or (C) is thereafter rightly
          obtained by the receiving party from a source other than the disclosing party
          without breaching any provision of these Terms of Service.
        </li>
      </ol>

      


      <h2>
        19. Intellectual Property and your Content
      </h2>
      <ol>
        <li>
          <span>19.1</span>
          We do not claim ownership of the Content you provide to Eventify; however,
          we do require a license to those Contents. You grant Eventify a non-exclusive,
          transferable, sub-licensable, royalty-free, worldwide right and license to host,
          use, distribute, expose, modify, run, copy, store, publicly perform, communicate
          to the public (including by telecommunication), broadcast, reproduce, make
          available, display, and translate, and create derivative works of any Content
          provided by you in connection with the Platform. We may use our rights under
          this license to operate, provide, and promote the Platform and to perform our
          obligations and exercise our rights under the Terms of Service. You represent,
          warrant, and agree that you have all necessary rights in the Content to grant
          this license. You irrevocably waive any and all moral rights you may have in the
          Content in favor of Eventify and agree that this waiver may be invoked by
          anyone who obtains rights in the materials through Eventify, including anyone
          to whom Eventify may transfer or grant (including by way of license or
          sublicense) any rights in the Content.
        </li>
        <li>
          <span>19.2</span>
          If you owned the Content before providing them to Eventify then, despite
          uploading them to your Eventify Platform they remain yours, subject to any
          rights or licenses granted in the Terms of Service or elsewhere. Removing your
          Account does not terminate any rights or licenses granted to the Contents that
          Eventify requires to exercise any rights or perform any obligations that arose
          during the Term.
        </li>
        <li>
          <span>19.3</span>
          You agree that Eventify can, at any time, review and delete any or all of the
          Content submitted to the Platform, although Eventify is not obligated to do so.
        </li>
        <li id="eventifyIntellectualProperty">
          <span>19.4</span>
          You grant Eventify a non-exclusive, transferable, sub-licensable, royalty-free,
          worldwide right and license to use the names, trademarks, service marks and
          logos associated with you (<b>“Your Trademarks”</b>) to operate, provide, and
          promote the Platform and to perform our obligations and exercise our rights
          under the Terms of Service. This license will survive any termination of the
          Terms of Service solely to the extent that Eventify requires the license to
          exercise any rights or perform any obligations that arose during the Term.
        </li>
      </ol>

      <h2>
        20. Eventify Intellectual Property
      </h2>
      <ol>
        <li>
          <span>20.1</span>
          You agree that you may not use any trademarks, logos, or service marks of
          Eventify, whether registered or unregistered (<b>“Eventify Trademarks”</b>) unless
          you are authorized to do so by Eventify in writing. You agree not to use or adopt
          any marks that may be considered confusing with the Eventify Trademarks.
          You agree that any variations or misspellings of the Eventify Trademarks would
          be considered confusing with the Eventify Trademarks.
        </li>
        <li>
          <span>20.2</span>
          You agree not to purchase, register, or use search engine or other pay-perclick keywords (such as Google Ads), trademarks, email addresses, social
          media names, or domain names (including without limitation top-level domains,
          sub-domains, and page URLs) that use or include Eventify or Eventify
          Trademarks or that use or include any terms that may be confusing with the
          Eventify Trademarks.
        </li>
        <li id="termAndTermination">
          <span>20.3</span>
          You acknowledge and agree that the Terms of Service do not give you any
          right to implement Eventify patents.
        </li>
      </ol>

      <h2>
        21. Term and Termination
      </h2>
      <ol>
        <li>
          <span>21.1</span>
          The term of these Terms of Service will begin on the date of your completed
          registration for use of a Platform and continue until terminated by us or by you,
          as provided below (<b>“Term”</b>).
        </li>
        <li>
          <span>21.2</span>
          You may cancel your Account and terminate the Terms of Service at any time
          by contacting Eventify via email <a href="mailto:info@eventify.lv">info@eventify.lv</a> and then following the specific
          instructions indicated to you in Eventify’s response.
        </li>
        <li>
          <span>21.3</span>
          Without limiting any other remedies, we may suspend or terminate your
          Account or the Terms of Service for any reason, without notice and at any time
          (unless otherwise required by law), including if we suspect that you (by
          conviction, settlement, insurance investigation, or otherwise) have engaged in
          fraudulent activity in connection with the use of the Platform. Termination of the
          Terms of Service will be without prejudice to any rights or obligations which
          arose prior to the date of termination.
        </li>
        <li>
          <span>21.4</span>
          Upon termination of the Account by either party for any reason:
          <ol>
            <li>
              <span>21.4.1</span>
              Eventify will cease providing you with the access to the Platform and
              any related services with it and you will no longer be able to access your
              Account;
            </li>
            <li>
              <span>21.4.2</span>
              unless otherwise provided in the Terms of Service, you will not be
              entitled to any refunds of any Fees, pro rata or otherwise;
            </li>
            <li>
              <span>21.4.3</span>
              any outstanding balance owed to Eventify for your use of the Platform
              through the effective date of such termination will immediately become
              due and payable in full.
            </li>
          </ol>
        </li>
        <li id="finalProvisions">
          <span>21.5</span>
          If there are any outstanding Fees owed by you at the date of termination of the
          Account, you will receive one final invoice via email. Once that invoice has been
          paid in full, you will not be charged again.
        </li>
      </ol>

      <h2>
        22. Final Provisions
      </h2>
      <ol>
        <li>
          <span>22.1</span>
          The Terms of Service, including the documents it incorporates by reference,
          constitute the entire agreement between you and Eventify and govern your use
          of the Platform and your Account, superseding any prior agreements between
          you and Eventify (including, but not limited to, any prior versions of the Terms
          of Service).
        </li>
        <li>
          <span>22.2</span>
          The failure of Eventify to exercise or enforce any right or provision of the Terms
          of Service will not constitute a waiver of such right or provision. If any provision
          of the Terms of Service, including all terms and conditions and other documents
          it incorporates by reference, is held by a court of competent jurisdiction to be
          contrary to law, such provision will be changed and interpreted so as to best
          accomplish the objectives of the original provision to the fullest extent allowed
          by law, and the remaining provision of the Terms of Service will remain in full
          force and effect.
        </li>
        <li>
          <span>22.3</span>
          If any substantial amendments are made to the Terms of Service, then you will
          be notified by email or through a notification via the Eventify website. If you
          continue using the Eventify website, you will be deemed to have accepted the
          amendments.
        </li>
        <li>
          <span>22.4</span>
          These Terms of Service will be governed by and construed and enforced in
          accordance with the laws of the <b>Republic of Latvia</b>. If the respective dispute
          resulting from the Terms of Service could not be settled by the negotiations,
          then the dispute will be finally solved in the courts of the Republic of Latvia. If
          any provision of the Terms of Service is held to be unenforceable, the parties
          will substitute for the affected provision an enforceable provision that
          approximates the intent and economic effect of the affected provision.
        </li>
        <li>
          <span>22.5</span>
          On termination, all related rights and obligations under the Terms of Service
          immediately terminate, except that you will remain responsible for performing
          all of your obligations in connection with transactions entered into before
          termination and for any liabilities that accrued before or as a result of
          termination; and Sections Account Terms, Provision of rental services between
          the User and the Provider, Returns, Error of the Provider, platform Fees,
          Payments and Payment terms between the Provider and the Users, Liability of
          Users, Limitations on Liability, Intellectual Property and your Content, Eventify
          intellectual Property will survive the termination or expiration of these Terms of
          Service.
        </li>
      </ol>





    </div>
  );
};









TermsOfServiceContentEn.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfServiceContentEn.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfServiceContentEn;
