import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TermsOfServiceContentEn from './TermsOfServiceContentEn';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const {rootClassName, className} = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <TermsOfServiceContentEn/>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const {string} = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
