import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  MultiLanguageSelect
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import MenuIcon from './MenuIcon';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentSearchParams
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      currentSearchParams={currentSearchParams}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const isPremiumCustomer = currentUser?.attributes?.profile?.metadata?.premium || currentUser?.attributes?.profile?.publicData?.premium;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        {/*<MenuItem key="ProfileSettingsPage">*/}
        {/*  <NamedLink*/}
        {/*    className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}*/}
        {/*    name="ProfileSettingsPage"*/}
        {/*  >*/}
        {/*    <span className={css.menuItemBorder} />*/}
        {/*    <FormattedMessage id="TopbarDesktop.profileSettingsLink" />*/}
        {/*  </NamedLink>*/}
        {/*</MenuItem>*/}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="BecomePremiumPage">
          {!isPremiumCustomer &&
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('BecomePremiumPage'))}
              name="BecomePremiumPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.becomePremiumCustomerLink" />
            </NamedLink>
          }
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );


  const desktopMenu = (
    <div className={css.desktopMenu}>
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>

      <NamedLink
        className={classNames(css.createListingLink, currentPageClass('SearchPage'))}
        name="SearchPage"
        to={{
          search:
            '?bounds=57.79422964%2C25.36425%2C56.11656801%2C22.9394862',
        }}
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.allListings" />
        </span>
      </NamedLink>

      <NamedLink className={css.createListingLink} name="CategoriesPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.allCategories" />
        </span>
      </NamedLink>
    </div>
  )

  const tabletMenu = (
    <Menu rootClassName={css.tabletMenu}>
      <MenuLabel className={css.tabletMenuLabel} isOpenClassName={css.tabletMenuLabelOpened}>
        <MenuIcon />
      </MenuLabel>
      <MenuContent className={css.tabletMenuContent} useDefaultStyles={false}>
        <MenuItem key="NewListingPage">
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="SearchPage">
          <NamedLink
            className={classNames(css.createListingLink, currentPageClass('SearchPage'))}
            name="SearchPage"
          >
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.allListings" />
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="CategoriesPage">
          <NamedLink className={css.createListingLink} name="CategoriesPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.allCategories" />
            </span>
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )


  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}


      {desktopMenu}
      {tabletMenu}

      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
      <MultiLanguageSelect />
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
