import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { getMonthStartInTimeZone, timestampToDate, sameDay } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, PrimaryButton, FieldTextInput, FieldQuantityInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import moment from 'moment';

import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import FieldStartDateAndTimeInput from './FieldStartDateAndTimeInput';
import FieldEndDateAndTimeInput from './FieldEndDateAndTimeInput';

import { getMonthlyTimeSlots, getTimeSlots } from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';
import * as validators from "../../util/validators";
const TODAY = new Date();

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      seatsError: false,
      availableSeatsLeft: null,
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
      currentTimeError: false,
      isOwnListingBookingError: false,
    }

    this.inputRef = React.createRef();

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    const data = {
      ...e,
      hostFeeRate: this.props.hostFeeRate,
    };
    this.props.onSubmit(data);
  }

  getSelectedMonth = (currentMonth) => {
    this.setState({ currentMonth })
  }

  handleError = (values) => {


    const currentValues = values.values;
    const { bookingEndDate, bookingStartDate } = currentValues;

    if (bookingEndDate && bookingStartDate) {
      const momentEndDate = moment(bookingEndDate.date)
      const momentStartDate = moment(bookingStartDate.date)
      if (momentEndDate.isBefore(momentStartDate)) {
        this.setState({
          currentTimeError: true
        })
      }

    }

  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime, bookingEndDate, bookingStartDate } = formValues.values;
    const { monthlyTimeSlots, timeZone, listing, fetchSpeculatedTransaction, publicData, speculateTransactionError } = this.props;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;
    const seats = formValues.values && parseInt(formValues.values.seats) ? parseInt(formValues.values.seats) : 1;
    const { hostFeeRate } = this.props;
    const isNextNightBookingStart = publicData?.availabilityEntries?.find(item => item.startTime === "00:00")
    const isNextNightBookingEnd = publicData?.availabilityEntries?.find(item => item.endTime === "00:00")
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;


    if (bookingEndDate && bookingStartDate && seats && bookingStartTime && bookingEndTime && !this.props.fetchLineItemsInProgress) {

      if (isNextNightBookingStart || isNextNightBookingEnd) {
        fetchSpeculatedTransaction(
          {
            listingId,
            bookingStart: startDate,
            bookingEnd: endDate,
            seats,
            listing: listing,
          },
          null
        );
      }


      const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
      const timeSlot = getTimeSlots(timeSlots, startDate, timeZone);
      const availableSeatsLeft = timeSlot && timeSlot[0] && timeSlot[0].attributes.seats;

      this.setState({ availableSeatsLeft })

      if (parseInt(formValues.seats) > availableSeatsLeft) {
        this.setState({
          seatsError: true
        })
      }
      if (parseInt(formValues.seats) <= availableSeatsLeft) {
        this.setState({
          seatsError: false
        })
      }

      if (this.props.isOwnListing) {
        this.setState({
          isOwnListingBookingError: true
        })
      }

      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, seats, hostFeeRate },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, availabilityPlanType, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            listing,
            publicData,
            speculateTransactionError
          } = fieldRenderProps;

          const {isOwnListingBookingError} = this.state;


          const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
          const seats = values.seats ? values.seats : 1;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                unitType: 'line-item/units',
                startDate,
                endDate,
                timeZone,
              }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError && speculateTransactionError === null && !isOwnListingBookingError;

          const quantityPlaceholderMessage = intl.formatMessage({
            id: 'BookingDatesForm.quantityPlaceholderMessage',
          });

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <>
              {this.state.availableSeatsLeft !== null && this.state.availableSeatsLeft > 1 && <span className={css.units}>{this.state.availableSeatsLeft} <FormattedMessage id="BookingDatesForm.unitsLeft" /></span>}
              <div className={css.priceBreakdownContainer}>
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </h3>
                <EstimatedBreakdownMaybe availabilityPlanType={availabilityPlanType} bookingData={bookingData} lineItems={lineItems} />
              </div>
            </>

          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const speculateTransactionErrorMaybe = speculateTransactionError ? (<span className={classNames(css.sideBarError, css.sideBarErrorMargin)}>
            {/* {speculateTransactionError.apiErrors[0].title} */}
            <FormattedMessage id="BookingDatesForm.isOwnListingBookingError" />
          </span>) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const submitDisabled =
            values.bookingStartTime == null ||
            values.bookingEndTime == null ||
            !values.hasOwnProperty('bookingEndDate') ||
            !values.hasOwnProperty('bookingStartDate') ||
            !showEstimatedBreakdown ||
            isOwnListingBookingError;

          const showSeatsField = !!values?.bookingStartTime && !!values?.bookingEndTime && this.state.availableSeatsLeft > 1 && speculateTransactionError === null;
          const isNextNightBookingStart = publicData?.availabilityEntries?.find(item => item.startTime === "00:00")
          const isNextNightBookingEnd = publicData?.availabilityEntries?.find(item => item.endTime === "00:00")

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              <FormSpy onChange={this.handleError} />
              {monthlyTimeSlots && timeZone && !(isNextNightBookingStart || isNextNightBookingEnd) ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  getSelectedMonth={this.getSelectedMonth}
                />
              ) : null}

              {monthlyTimeSlots && timeZone && (isNextNightBookingStart || isNextNightBookingEnd) ? (
                <FieldStartDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  getSelectedMonth={this.getSelectedMonth}
                />
              ) : null}

              {monthlyTimeSlots && timeZone && (isNextNightBookingStart || isNextNightBookingEnd) ? (
                <FieldEndDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  getSelectedMonth={this.getSelectedMonth}
                />
              ) : null}

              {showSeatsField && (
                <FieldQuantityInput
                  id="seats"
                  name="seats"
                  className={css.quantity}
                  type="number"
                  label={<FormattedMessage id="BookingDatesForm.quantityMessage" />}
                  placeholder={quantityPlaceholderMessage}
                  validate={validators.quantityForTime(timeSlots, startDate, timeZone)}
                  inputRef={this.inputRef}
                  isUncontrolled
                  defaultValue={seats}
                  availableSeatsLeft={this.state.availableSeatsLeft}
                />
              )}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>

              {speculateTransactionErrorMaybe}

              {isOwnListingBookingError && !speculateTransactionError && <span className={classNames(css.sideBarError, css.sideBarErrorMargin)}>
                <FormattedMessage id="BookingDatesForm.isOwnListingBookingError" />
              </span>}

            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
