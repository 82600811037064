import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-location',
    predictionPlace: {
      address: 'Current location',
      bounds: new LatLngBounds(new LatLng(47.850263152770715, 35.025991985344795), new LatLng(47.83229684722928, 34.999224014655205)),
    },
  },
  {
    id: 'default-riga',
    predictionPlace: {
      address: 'Riga, Latvia',
      bounds: new LatLngBounds(new LatLng(57.122429540849, 24.3238329601147), new LatLng(56.8554000585396, 23.9062490684482)),
    },
  },
  {
    id: 'default-jurmala',
    predictionPlace: {
      address: 'Jurmala, Latvia',
      bounds: new LatLngBounds(new LatLng(57.00854, 23.970316), new LatLng(56.923993, 23.47276)),
    },
  },
  {
    id: 'default-sigulda',
    predictionPlace: {
      address: 'Sigulda, Latvia',
      bounds: new LatLngBounds(new LatLng(57.188267, 24.898266), new LatLng(57.138421, 24.804193)),
    },
  },
  {
    id: 'default-ogre',
    predictionPlace: {
      address: 'Ogre, Latvia',
      bounds: new LatLngBounds(new LatLng(56.833843, 24.658361), new LatLng(56.78851, 24.560631)),
    },
  },
  {
    id: 'default-jelgava',
    predictionPlace: {
      address: 'Jelgava, Latvia',
      bounds: new LatLngBounds(new LatLng(56.691616, 23.800046), new LatLng(56.596721, 23.623382)),
    },
  },
  {
    id: 'default-cesis',
    predictionPlace: {
      address: 'Cesis, Latvia',
      bounds: new LatLngBounds(new LatLng(57.338253, 25.320685), new LatLng(57.284744, 25.218184)),
    },
  },
];
export default defaultLocations;
