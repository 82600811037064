import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  NamedLink,
} from '../../components';

import css from './IconPremium.module.css';

const IconPremiumComponent = props => {
  const { intl, user } = props;

  if (!user?.attributes?.profile?.metadata?.premium || !user?.attributes?.profile?.publicData?.premium) {
    return null
  }

  const title = intl.formatMessage({
    id: 'PremiumCustomer.title',
  });

  return (
    <NamedLink
      name="BecomePremiumPage"
      title={title}
      className={css.root}>
      {/* <svg
        className={css.icon}
        xmlns="http://www.w3.org/2000/svg"
        overflow="hidden"
        viewBox="0 0 1024 1024"
      >
        <path d="M692.48 768L512 659.2 331.52 768l47.787-205.227L220.16 424.96l209.92-17.92L512 213.333l81.92 193.28 209.92 17.92-159.147 137.814L692.48 768M512 85.333c-235.947 0-426.667 192-426.667 426.667A426.667 426.667 0 00512 938.667 426.667 426.667 0 00938.667 512 426.667 426.667 0 00512 85.333z"></path>
      </svg> */}
      <span className={css.icon}>
        💎
      </span>
    </NamedLink>
  );
};

const IconPremium = compose(

  injectIntl
)(IconPremiumComponent);

export default IconPremium;




