import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PrivacyPolicyContentEn from './PrivacyPolicyContentEn';
import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const {rootClassName, className} = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <PrivacyPolicyContentEn/>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const {string} = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
