import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {Form, Button, FieldTextInput, FieldPhoneNumberInput} from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import CustomSubcategorySelectFieldMaybe from './CustomSubcategorySelectFieldMaybe';
import { CategoriesEn, Categories } from '../../categories';
import config from '../../config';
import css from './EditListingDescriptionForm.module.css';
import * as validators from "../../util/validators";
import {ensureCurrentUser} from "../../util/data";

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        saveActionMsg,
        fetchErrors,
        currentUser,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const emailLabel = intl.formatMessage({
        id: 'ContactDetailsForm.emailLabel',
      });

      const user = ensureCurrentUser(currentUser);
      const { email: currentEmail, profile } = user.attributes;


      // phone
      const protectedData = profile.protectedData || {};
      const currentPhoneNumber = protectedData.phoneNumber;

      // has the phone number changed

      const phonePlaceholder = intl.formatMessage({
        id: 'ContactDetailsForm.phonePlaceholder',
      });
      const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });


      // email
      const emailPlaceholder = currentEmail || '';

      const emailRequiredMessage = intl.formatMessage({
        id: 'ContactDetailsForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ContactDetailsForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      // EVENTIFY Categories & Subcategories Logic

      const currentLocale = config.locale;
      let eventifyCategories;
  
      if(currentLocale === "lv") {
        eventifyCategories = Categories;
      } else {
        eventifyCategories = CategoriesEn;
      }

      let selectedCategory = formRenderProps.values.category;
      let thisCategory = eventifyCategories.find(thisCat => thisCat.id === selectedCategory);
      let subcategories = thisCategory ? thisCategory.subcategories : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldTextInput
            id="rules"
            name="rules"
            className={css.description}
            type="textarea"
            label={rulesLabelMessage}
            placeholder={rulesPlaceholderMessage}
          />

          <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={eventifyCategories}
            intl={intl}
          />

          {selectedCategory ? (
            <CustomSubcategorySelectFieldMaybe
              id="subcategory"
              name="subcategory"
              subcategories={subcategories}
              intl={intl}
            />
          ) : null}

          <FieldTextInput
            type="email"
            name="email"
            id="email"
            label={emailLabel}
            placeholder={emailPlaceholder}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />

          <FieldPhoneNumberInput
            className={css.phone}
            name="phoneNumber"
            id="phoneNumber"
            label={phoneLabel}
            placeholder={phonePlaceholder}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            <FormattedMessage id={saveActionMsg} />
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
