import React from 'react';
import PropTypes from 'prop-types';

import css from './IconFlags.module.css';

const IconFlags = props => {
  const { className, flag } = props;

  if (flag === "lt") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="24"
        version="1.1"
        viewBox="0 0 40 24"
      >
        <g fillOpacity="1" stroke="none">
          <path fill="#C1272D" d="M0 0H40V24H0z"></path>
          <path fill="#006A44" fillRule="nonzero" d="M0 0h40v16H0zm0 0"></path>
          <path fill="#FDB913" fillRule="nonzero" d="M0 0h40v8H0zm0 0"></path>
        </g>
      </svg>
    );
  }
  else if (flag === "lv") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="30"
        viewBox="0 0 60 30"
      >
        <g>
          <path fill="#9E3039" d="M0 0H60V30H0z"></path>
          <path fill="#FFF" d="M0 12h60v6H0"></path>
        </g>
      </svg>
    );
  }
  else if (flag === "et") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="990"
        height="630"
        viewBox="0 0 33 21"
        className={className}
      >
        <path fill="#FFF" d="M0 0H33V21H0z"></path>
        <path d="M0 0H33V14H0z"></path>
        <path fill="#0072ce" d="M0 0H33V7H0z"></path>
      </svg>
    );
  }
  else if (flag === "ru") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="900"
        height="600"
        viewBox="0 0 9 6"
        className={className}
      >
        <path fill="#fff" d="M0 0H9V3H0z"></path>
        <path fill="#d52b1e" d="M0 3H9V6H0z"></path>
        <path fill="#0039a6" d="M0 2H9V4H0z"></path>
      </svg>
    );
  }
  else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1000"
        height="600"
        viewBox="0 0 50 30"
        className={className}
      >
        <clipPath id="t">
          <path d="M25 15h25v15zv15H0zH0V0zV0h25z"></path>
        </clipPath>
        <path fill="#012169" d="M0 0v30h50V0z"></path>
        <path stroke="#fff" strokeWidth="6" d="M0 0l50 30m0-30L0 30"></path>
        <path
          stroke="#C8102E"
          strokeWidth="4"
          d="M0 0l50 30m0-30L0 30"
          clipPath="url(#t)"
        ></path>
        <path
          fill="#C8102E"
          stroke="#FFF"
          strokeWidth="2"
          d="M-1 11h22V-1h8v12h22v8H29v12h-8V19H-1z"
        ></path>
      </svg>
    );
  }


};

IconFlags.defaultProps = { className: null };

const { string } = PropTypes;

IconFlags.propTypes = {
  className: string,
};

export default IconFlags;
