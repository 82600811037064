import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 3;

// ================ Action types ================ //

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/LandingPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/LandingPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/LandingPage/SEARCH_MAP_LISTINGS_ERROR';

export const TOP_LISTINGS_REQUEST = 'app/LandingPage/TOP_LISTINGS_REQUEST';
export const TOP_LISTINGS_SUCCESS = 'app/LandingPage/TOP_LISTINGS_SUCCESS';
export const TOP_LISTINGS_ERROR = 'app/LandingPage/TOP_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  topListingIds: [],
  topListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };


    case TOP_LISTINGS_REQUEST:
      return {
        ...state,
        topListingsError: null,
      };
    case TOP_LISTINGS_SUCCESS: {
      const topListingIds = unionWith(
        state.topListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        topListingIds,
      };
    }
    case TOP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, topListingsError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const topListingsRequest = () => ({ type: TOP_LISTINGS_REQUEST });

export const topListingsSuccess = response => ({
  type: TOP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const topListingsError = e => ({
  type: TOP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'publicData.availability'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData.companyName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const topListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(topListingsRequest(searchParams));


  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
    pub_isTopListing: true,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'publicData.availability'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData.companyName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(topListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(topListingsError(storableError(e)));
      throw e;
    });
};
