import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Form as FinalForm } from "react-final-form";
import { compose } from "redux";

import { Form, PrimaryButton, FieldTextInput } from "../../components";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";

import css from './CashBookingForm.module.css';

const CashBookingFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        handleSubmit,
        intl,
        submitInProgress,
        authorDisplayName,
        invalid,
      } = fieldRenderProps;
      const classes = classNames(rootClassName || css.root, className);

      const messageOptionalText = intl.formatMessage({
        id: 'StripePaymentForm.messageOptionalText',
      });
      const messagePlaceholder = intl.formatMessage(
        { id: 'StripePaymentForm.messagePlaceholder' },
        { name: authorDisplayName }
      );
      const initialMessageLabel = intl.formatMessage(
        { id: 'StripePaymentForm.messageLabel' },
        { messageOptionalText: messageOptionalText }
      );

      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h3 className={css.messageHeading}>
           <FormattedMessage id="StripePaymentForm.messageHeading" />
          </h3>

          <FieldTextInput
            type="textarea"
            id="message"
            name="initialMessage"
            label={initialMessageLabel}
            placeholder={messagePlaceholder}
            className={css.message}
          />

          <PrimaryButton
            className={css.submitButton}
            type="submit"
            disabled={submitDisabled}
            inProgress={submitInProgress}
          >
            <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

CashBookingFormComponent.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

CashBookingFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const CashBookingForm = compose(injectIntl)(CashBookingFormComponent);
CashBookingForm.displayName = 'CashBookingForm';

export default CashBookingForm;
