import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 526.7 149.71"
    >
      <path
        d="M74.89 75.6v2.69a22.88 22.88 0 01-.13 2.55H26.22A11.67 11.67 0 0027.9 86a14.41 14.41 0 003.63 4 17.36 17.36 0 005 2.62 17.55 17.55 0 005.72.94 18.55 18.55 0 008.87-2 17.53 17.53 0 005.92-5l15.33 9.68a31.06 31.06 0 01-12.47 10.49 41.23 41.23 0 01-17.9 3.7 42.78 42.78 0 01-14.25-2.36 34.29 34.29 0 01-11.77-6.86A31.57 31.57 0 018 90.12a37.08 37.08 0 01-2.9-15.06A37.74 37.74 0 017.93 60.2a33.57 33.57 0 017.66-11.29A34.39 34.39 0 0127 41.71a39.18 39.18 0 0114.26-2.55 36.06 36.06 0 0113.58 2.49 29.45 29.45 0 0110.62 7.19 33.29 33.29 0 016.93 11.43 43.94 43.94 0 012.5 15.33m-20.3-8.47a13.2 13.2 0 00-3.3-9q-3.29-3.77-9.88-3.78a16.83 16.83 0 00-5.92 1 15.14 15.14 0 00-4.7 2.76 13.52 13.52 0 00-3.23 4.1 12.14 12.14 0 00-1.34 4.91z"
        className="cls-1"
      ></path>
      <path
        d="M124.39 109.41L100.58 109.41 74.23 42.44 98.57 42.44 112.69 85.88 113.09 85.88 127.21 42.44 150.74 42.44 124.39 109.41z"
        className="cls-1"
      ></path>
      <path
        d="M220.19 75.6v2.69a22.8 22.8 0 01-.14 2.55h-48.54a11.67 11.67 0 001.68 5.16 14.41 14.41 0 003.63 4 17.85 17.85 0 0010.69 3.56 18.56 18.56 0 008.88-2 17.4 17.4 0 005.91-5l15.33 9.68a31.06 31.06 0 01-12.44 10.56 41.23 41.23 0 01-17.95 3.7 42.78 42.78 0 01-14.24-2.43 34.39 34.39 0 01-11.77-6.86 31.57 31.57 0 01-7.93-11.09 37.09 37.09 0 01-2.89-15.06 37.74 37.74 0 012.82-14.86 33.45 33.45 0 017.67-11.29 34.19 34.19 0 0111.43-7.2 39.12 39.12 0 0114.25-2.55 36 36 0 0113.58 2.49 29.49 29.49 0 0110.63 7.19 33.42 33.42 0 016.92 11.43 43.93 43.93 0 012.49 15.33m-20.31-8.47a13.24 13.24 0 00-3.29-9q-3.3-3.76-9.89-3.77a16.74 16.74 0 00-5.91 1 15.19 15.19 0 00-4.71 2.76 13.68 13.68 0 00-3.23 4.1 12.14 12.14 0 00-1.34 4.91zM274.24 109.41V72.83a29 29 0 00-.48-5.37 14.62 14.62 0 00-1.54-4.46 8 8 0 00-3-3.09 9.58 9.58 0 00-4.9-1.15 11.17 11.17 0 00-5.18 1.15 10.43 10.43 0 00-3.7 3.16 14.15 14.15 0 00-2.22 4.64 20 20 0 00-.74 5.44v36.31h-22.21v-67h21.37v9.28h.28a22.23 22.23 0 013.15-4.24 19.31 19.31 0 014.58-3.56 28.75 28.75 0 015.71-2.42 22.77 22.77 0 016.59-.94A24.81 24.81 0 01283.38 43a22 22 0 017.6 6.25 25 25 0 014.17 8.75 40 40 0 011.27 10v41.42zM334.26 58.85V84.5q0 4.73 1.82 7.08T342.6 94a23.43 23.43 0 003.43-.27 11.8 11.8 0 003-.81l.26 16.13a36.68 36.68 0 01-5.78 1.42 41.24 41.24 0 01-7 .6 33 33 0 01-11.3-1.68 17.51 17.51 0 01-7.33-4.86 19 19 0 01-4-7.55 36.5 36.5 0 01-1.21-9.77V58.85H302V42.44h10.62V24.83h21.65v17.61H350v16.41z"
        className="cls-1"
      ></path>
      <path
        d="M360.14 42.44H382.19V109.39999999999999H360.14z"
        className="cls-1"
      ></path>
      <path
        d="M439.79 24a18.63 18.63 0 00-2.82-.67 19.56 19.56 0 00-3.23-.27 10 10 0 00-5 1.08 7.87 7.87 0 00-2.95 2.89 12 12 0 00-1.48 4.1 26.13 26.13 0 00-.4 4.57v6.72h14.92v16.43h-14.9v50.56h-22.06V58.85h-12.24V42.44h12.24V36a49.88 49.88 0 011.35-11.77 25.31 25.31 0 014.64-9.81 22.58 22.58 0 018.87-6.73 34.19 34.19 0 0113.92-2.49 47 47 0 015.58.34 30.76 30.76 0 015 1zM488.73 119.49a58.55 58.55 0 01-4.51 9.35 28 28 0 01-5.92 7 23.16 23.16 0 01-8.06 4.37 36.15 36.15 0 01-11 1.48 52.32 52.32 0 01-6.39-.4 39.57 39.57 0 01-5.72-1.08l2.43-18.55a24.75 24.75 0 003.56.87 21.6 21.6 0 003.56.34c3.41 0 5.87-.79 7.4-2.36a19.15 19.15 0 003.9-6.65l2.15-5.52-28-65.89h24.47l14.93 43h.4l13.32-43h23.39z"
        className="cls-1"
      ></path>
      <path
        d="M388.11 14c2.31 7.83-11.55 18.42-17.3 21.51a8.39 8.39 0 01-9.81-1.24c-3.65-3.29-5.57-10.67-5.57-10.67s-2.4-7.24-1.12-12a8.38 8.38 0 017.58-6.31c6.52-.52 23.91.84 26.22 8.71z"
        className="cls-2"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
