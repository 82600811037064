import React, { useState, useEffect } from 'react';
import { Modal } from '..';
import { EditListingPricingForm } from '../../forms';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { FormattedMessage } from '../../util/reactIntl';
import { languagesHelper as language } from '../../helpers/languages';
import css from "./EditListingPricingPanel.module.css";

export const MultiOptionAvailability = (props) => {
  const [availability, setAvailabilty] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [availabilityType, setAvailabilityType] = useState('');
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const {
    initialAvailabilityPlan,
    initialHourlyAvailabilityPlan,
    onSubmit,
    availabilityPlanOption,
    onManageDisableScrolling,
    initialValues,
    onChange,
    saveActionMsg,
    disabled,
    ready,
    updated,
    updateInProgress,
    fetchErrors,
    intl,
    currentUser,
  } = props;

  useEffect(() => {
    if (availabilityPlanOption) {
      switch (availabilityPlanOption) {
        case 'daily':
          setAvailabilty('daily');
          break;
        case 'hourly':
          setAvailabilty('hourly');
          break;
      }
    }
  }, [availabilityPlanOption]);

  const handleAvailabilityOptionChange = e => {
    setAvailabilityType(e.target.value);
    setShowModal(true);
  };

  const handleProceed = () => {
    onSubmit({
      publicData: {
        availability: availabilityType,
      },
    });
    setAvailabilty(availabilityType);
    setShowModal(false);
  };

  const handleProceedCancel = () => {
    setAvailabilityType('');
    setAvailabilty('');
    setShowModal(false);
  };

  /** Show Confirmation Popup on Change availability Type */

  const ConfirmationModal = onManageDisableScrolling ? (
    <Modal
      id="availabilityOption"
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <h1>
        <FormattedMessage id="EditListingPricingForm.areYouSure" />
      </h1>
      <p>
        <FormattedMessage id="EditListingPricingForm.proceedCarefully" />
      </p>
      <div>

        <PrimaryButton onClick={handleProceed} inProgress={loadingInProgress}>
          <FormattedMessage id="EditListingPricingForm.submit" />
        </PrimaryButton>

        <br />

        <SecondaryButton
          onClick={handleProceedCancel}
          disabled={loadingInProgress}
        >
          <FormattedMessage id="EditListingPricingForm.cancel" />
        </SecondaryButton>
      </div>
    </Modal>
  ) : null;

  return (
    <>
      {/* Hide or show option on the basis of property availability in publicData */}
      {typeof availabilityPlanOption === 'undefined' && (
        <>
          <div>
            <p>
              <FormattedMessage id="EditListingPricingForm.choosePlane" />
            </p>
          </div>
          <select selected={availability} onChange={handleAvailabilityOptionChange}>
            <option value="" disabled hidden selected>
              {intl.formatMessage({ id: 'EditListingPricingForm.selectOption' })}
            </option>
            {[
              { key: 'hourly', label: 'Hourly' },
              { key: 'daily', label: 'Daily' },
            ].map(option => (
              <option key={option.key} value={option.key}>
                {language.labelsTranslator(option.key, intl)}
                {/* {option.label} */}
              </option>
            ))}
          </select>
        </>
      )}
      {ConfirmationModal}

      {availability && (
        <EditListingPricingForm
          className={css.form}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onChange={onChange}
          saveActionMsg={saveActionMsg}
          disabled={disabled}
          ready={ready}
          updated={updated}
          updateInProgress={updateInProgress}
          fetchErrors={fetchErrors}
          availabilityPlanType={availabilityPlanOption}
          currentUser={currentUser}
        />
      )}
    </>
  );
}

export default MultiOptionAvailability;
