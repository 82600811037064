import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicyContentEn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <p>
        <b>Privacy Policy</b> (hereinafter the Privacy Policy) describes how Eventify Processes Personal Data.
        To contact Eventify and/or exercise the rights of a Data Subject, see <b>Section 10 and 11</b> of the Privacy Policy.
      </p>

      <h2>
        1. Definitions
      </h2>

      <p>
        <b>“Data Controller”</b> means anyone who alone or jointly with others determines the purposes and means of the
        Processing of Personal Data. For the Processing of Personal Data described in this Privacy Policy, Eventify is the Data Controller.
      </p>
      <p>
        <b>“Personal Data”</b> means any information directly or indirectly related to the Data Subject.
      </p>
      <p>
        <b>“Data Processor”</b> means anyone who Processes Personal Data on behalf of the Data Controller.
      </p>
      <p>
        <b>“Data Subject”</b> means any natural person who uses, has used or has expressed a wish to use the Platform or is in
        other way related to the use and/or user of any of the Platform and/or has any other relationship with Eventify established
        before the Privacy Policy entered into force.
      </p>
      <p>
        <b>“Data Protection Legislation”</b> means the applicable EU and national data protection legislation that Eventify is
        subject to, for example, Regulation (EU) 2016/679 (General Data Protection Regulation or the GDPR).
      </p>
      <p>
        <b>“EU/EEA”</b> means the European Union/European Economic Area.
      </p>
      <p>
        <b>“Processing”</b> means any operation or set of operations performed with regard to Personal Data, whether or not performed
        by automated means, such as collection, recording, organization, storage, adaptation, alteration, retrieval, use,
        combination, erasure or destruction.
      </p>
      <p>
        <b>“Recipient”</b> means a natural or legal person, public authority or another body, to whom Eventify is entitled to disclose Personal Data.
      </p>
      <p>
        <b>“Regulatory Legislation”</b> means the applicable legal acts that Eventify is subject to, for example,
        relating to anti-money laundering, commercial activity, data protection, taxes, bookkeeping, payment and other.
      </p>

      <h2>
        2. General provision
      </h2>
      <p>
        This Privacy Policy describes how we process the Personal Data of the Members and other visitors of the Eventify Platform. Unless
        the context otherwise requires, all capitalized terms used but not defined herein shall have the meanings set
        forth in the Terms of Service.
      </p>
      <p>
        We may update this Privacy Policy from time to time. However, we will not make substantial changes to this Privacy Policy
        without providing prior notice.You can find the current version on the Platform.
      </p>
      <p>
        Please note that this Privacy Policy only applies to the processing of Personal
        Data carried out by Eventify as a Data Controller.
      </p>
      <p>
        Detailed information on the Processing of Personal Data might be additionally described in agreements and other
        documents related to the Platform, as well as on Platform or be available at a separate request.
        <br />
        Within the framework of Data Protection Legislation, Eventify ensures the confidentiality of Personal Data
        related to Data Subject. Eventify has implemented appropriate technical and organizational measures to
        safeguard Personal Data from unauthorized access, unlawful disclosure, accidental loss, modification,
        destruction or any other unlawful Processing.
      </p>
      <p>
        Eventify engages Data Processors for Processing of Personal Data and takes necessary steps to ensure that
        Processing of Personal Data by Data Processors takes place under a contract or Regulatory Legislation and
        according to documented instructions of Eventify.
      </p>

      <h2>
        3. Processing of Personal Data
      </h2>
      <h3>
        3.1. Collection of Personal Data and categories of data subjects
      </h3>

      <p>
        Personal Data is collected from the Members directly and from the Member’s use of the Platform, as well as indirectly
        from external sources such as public and private registers or other providers of databases or
        other persons (see Section 8). Eventify may save uploaded images and e-mail communication or otherwise document
        the Member's interaction and communication with Eventify.
        <br />
        Eventify primarily collects from and Processes Personal Data about Members. Eventify also collects
        from and Processes Personal Data of such natural persons as:
      </p>
      <ul>
        <li>
          legal representatives, authorized persons, contact persons, transaction partners, agents, payers;
        </li>
        <li>
          holders of rented objects;
        </li>
        <li>
          who are connected to Eventify Members – legal persons, for example: board members, corporate representatives,
          signatories, ultimate beneficial owners;
        </li>
        <li>
          Eventify’s cooperation partners’ beneficial owners, representatives and employees, as well as external stakeholders,
          for example: journalists and followers on social media;
        </li>
        <li>
          attendees of Eventify events and visitors of Eventify premises.
        </li>
      </ul>

      <h3>
        3.2. Categories of Personal Data
      </h3>
      <p>
        Examples of Personal Data categories which Eventify collects and Processes:
      </p>
      <ul>
        <li>
          <b>Identification data</b> such as name, personal identification number, date of birth.
        </li>
        <li>
          <b>Contact data</b> such as address, phone number, email address, language of communication.

        </li>
        <li>
          <b>Account data</b> such as card number and/or bank account number, details about bank.
        </li>
        <li>
          <b>Communication and device data</b> such as the data contained in messages, emails, visual images,
          video and/or audio recordings, as well as other conversations and interactions, collected when
          the Member visits Eventify premises, data related to the Member's visit at Eventify websites and
          communication through Eventify Platform and/or mobile app.
        </li>
        <li>
          <b>Data about habits</b>, preferences and satisfaction such as the activeness of using the Platform,
          history of purchases on the Platform, personal settings, survey responses, Member's satisfaction.
        </li>
        <li>
          <b>Demographic data</b> such as country of residence, date of birth and place.
        </li>
      </ul>
      <p>
        Eventify does not process sensitive data related to the Member’s health, ethnicity, religious or political beliefs
        unless required by law or in specific circumstances where, for example,
        the Member reveals such data while using the Platform.
      </p>

      <h2>
        4. Profiling ang and automated decision making
      </h2>
      <p>
        Profiling is any form of automated Processing of Personal Data used to assess certain personal
        characteristics of the Member in particular to analyze or predict, for example, personal preferences,
        interests, place of residence. Profiling is used, for example, to make analysis based on performance
        of agreements and for marketing activities and service development based on Eventify’s legitimate interest or the Member’s consent.
      </p>
      <p>
        Eventify may provide automated decision-making based on profiling to improve the Member's user experience of the Platform,
        such as customizing the display of the Platform to the device used and creating suitable offers.
      </p>

      <h2>
        5. Legal bases and purposes of Processing Personal Data
      </h2>
      <h3>
        5.1. performance of agreements
      </h3>

      <p>
        Performance of agreements is one of the main legal bases according to which Eventify
        Processes Personal Data. Examples of purposes of such Processing include:
      </p>
      <ul>
        <li>
          To take steps at the request of the Member prior to entering into an agreement, as well as to conclude,
          amend, execute, maintain and terminate an agreement with the Member.
        </li>
        <li>
          To handle domestic and international transactions via financial institutions, settlement and payment systems.
        </li>
        <li>
          To manage relations with the Members, as well as to identify and authenticate, provide, control and administer access to the Platform.
        </li>
        <li>
          To verify and handle commercial transactions and other business communication.
        </li>
      </ul>

      <h3>
        5.2. Compliance with legal obligations
      </h3>
      <p>
        For complying with legal obligations in accordance with the Regulatory Legislation,
        Eventify is required to process Personal Data for such purposes as:
      </p>
      <ul>
        <li>
          To check and verify the Member’s identity and to keep Personal Data updated and correct by verifying
          and enriching data through external and internal information sources.
        </li>
        <li>
          To exchange Personal Data with third-party payment providers to provide account information and/or payment initiation services.
        </li>
        <li>
          To manage incidents, including data breaches.
        </li>
        <li>
          To comply with other legal obligations in accordance with the Regulatory Legislation.
        </li>
      </ul>

      <h3>
        5.3. legitimate interest
      </h3>
      <p>
        Some purposes of the Member’s Personal Data Processing are based on Eventify’s legitimate
        interests which are balanced against the Member’s as a data subject’s interests and rights.
        Examples of such purposes of Processing are:
      </p>

      <ul>
        <li>
          To maintain, develop, examine and improve Eventify business, the Platform, the Member’s user experience.
        </li>
        <li>
          To strengthen Member's satisfaction and loyalty, and Eventify’s brand, as well as to perform surveys, analyses, statistics.
        </li>
        <li>
          To organize lotteries, competitions, campaigns and events for the Members.
        </li>
        <li>
          To protect the interests of the Members and/or Eventify and Eventify employees, including security measures.
        </li>
        <li>
          To prevent, limit and investigate any misuse or unlawful use or disturbance of the Platform, including fraud prevention.
        </li>
        <li>
          To ensure adequate functionality of the Platform and the safety of information within
          the Platform, as well as to improve, develop and maintain Eventify
          Website, mobile app, including testing of the Platform.
        </li>
        <li>
          To establish, exercise, defend, assign or sell legal claims, handle complaints, as well
          as to retain information for this purpose.
        </li>
        <li>
          To record phone calls and video streams with the Members for Eventify
          service quality assurance and claims processing purposes.
        </li>
        <li>
          To provide payment initiation and/or account information to the Members.
        </li>
      </ul>

      <h3>
        5.4. Consent
      </h3>
      <p>
        In some cases, Eventify will ask for the Member’s consent to Process Personal Data. In those cases,
        the Member will be separately informed about the particular purpose of Processing.
        The Member can withdraw a given consent at any time.
      </p>

      <h2>
        6. Recipient and source of Personal Data
      </h2>
      <p>
        To be able to provide functionality of the Platform, Eventify may share the Member’s Personal Data with Recipients.
        <br />
        These Recipients are in general:
      </p>
      <ul>
        <li>
          Authorities and officials such as supervisory authorities, tax authorities, law enforcement agencies,
          sworn bailiffs, sworn notaries, Notary Council, courts, out-ofcourt dispute resolution bodies.
        </li>
        <li>
          Third party payment providers, in case Eventify has a legal obligation and/or contract to provide
          such entities access to the Member’s Personal Data or Members provide their Personal Data by themselves.
        </li>
        <li>
          Financial and legal consultants, auditors, bookkeepers or any other service providers of Eventify, Eventify authorized persons.
        </li>
        <li>
          Debt collection service providers, assignees, insolvency administrators.
        </li>
        <li>
          Persons and suppliers related to the functionality of the Platform to Eventify such as providers of
          video surveillance, telecommunications, IT, hosting, cloud computing services, archiving, postal services and other.
        </li>
      </ul>
      <p>
        Eventify will not share more Personal data than necessary for the particular purpose of Processing.
        Recipients may Process the Personal data as Data Processors and/or as Data Controllers. When the
        Recipient is Processing Members’s Personal data on its own behalf as a Data Controller, the Recipient is
        responsible for providing information to data subjects on such Processing of Personal data. In such cases
        Eventify advises the Member to contact this Recipient for information on the Processing of
        Personal Data by the Recipient.
      </p>

      <h2>
        7. Geographic area of Processing
      </h2>
      <p>
        As a general rule, Member’s Personal Data is Processed within the EU/EEA but in some cases
        transferred to and Processed in countries outside of the EU/EEA.
      </p>
      <p>
        The transfer and Processing of Personal Data outside of the EU/EEA can
        take place provided there is a legal basis and one of the following conditions:
      </p>
      <ul>
        <li>
          The country outside of the EU/EEA where the Recipient is located has an adequate level of data protection as decided by the EU Commission.
        </li>
        <li>
          Data Controller or Data Processor has provided appropriate safeguards, for example, the agreement that includes
          the EU Standard Contractual Clauses or other authorized contractual clauses are concluded,
          approved codes of conduct or certification mechanisms.
        </li>
        <li>
          There are derogations for specific situations applicable, for example, Member’s explicit consent,
          performance of a contract with the Member, conclusion or performance of a contract concluded in the interest of Member,
          establishment, exercise or defense of legal claims, important reasons of public interest.
        </li>
      </ul>
      <p>
        Upon request, the Member can receive further details on Personal Data transfers to countries outside of the EU/EEA.
      </p>

      <h2>
        8. Retention period
      </h2>
      <p>
        Personal Data will be retained for no longer than is necessary for the particular purpose of Processing for
        which the data is collected, or which is stipulated in the Regulatory Legislation. Personal Data will be Processed
        by Eventify as long as the contractual relationship with the Member exists. In cases when the processing of Personal
        Data takes place based on the Member's consent, the Personal Data will be retained as long as the consent is valid.
        Other retention periods may be established and applicable when the Personal Data is Processed for purposes based on
        Eventify legitimate interest, for example, for the establishment, exercise or defense of legal claims, for a maximum
        period of limitation according to the Regulatory Legislation. In all cases, Eventify limits the Processing of
        Personal data to a minimum.
      </p>

      <h2>
        9. Cookies
      </h2>
      <p>
        Eventify uses cookies at its Website. The cookies are used as stated in Eventify Cookie policy available on the Website.
      </p>

      <h2>
        10. Rights of a Data Subject
      </h2>
      <p>
        Under the Data Protection Legislation, the Member has rights of a data subject in regard to Eventify Processing of Personal Data.
        <br />
        Such rights are:
      </p>
      <ul>
        <li>
          Receive confirmation if the Member’s Personal Data is being Processed by Eventify and, if so, then to access it.
        </li>
        <li>
          Require the Member’s Personal Data to be corrected if it is inadequate, incomplete or incorrect.
        </li>
        <li>
          Require the erasure of the Member’s Personal Data.
        </li>
        <li>
          Restrict the Processing of the Member's Personal Data.
        </li>
        <li>
          Object to Processing of the Member’s Personal Data if processing is based on Eventify legitimate interests.
        </li>
        <li>
          Object to Processing of the Member's Personal Data for direct marketing.
        </li>
        <li>
          Receive the Personal Data that is provided by the Member and is being Processed based on consent or
          performance of an agreement in a structured, commonly used electronic format and, where feasible,
          transmit such data to another service provider (right to data portability).
        </li>
        <li>
          Withdraw the consent to Process the Member’s Personal Data.
        </li>
        <li>
          Request not to be subject to a fully automated decision-making, including profiling, if such decision making
          has legal effects or similarly significantly affects the Member. This right does not apply if the decision-making
          is necessary in order to enter into or to perform an agreement with the Member, if the decision-making is permitted
          under the Data Protection Legislation or if the Member has provided an explicit consent.
        </li>
      </ul>
      <p>
        Taking into account that Eventify might process a large amount of information and in order to execute the Data
        Subject request as correctly as possible, Eventify may ask the Member to specify information, Processing
        activities or timeframe to which the data subject request relates.
      </p>
      <p>
        The Member can exercise the rights of a Data Subject by submitting to Eventify an identified request by
        sending a request signed with e-signature via email. Answers to the Data Subject’s request will be provided
        not later than within one month of receipt of the request; when necessary, this period can be extended by
        two further months.
      </p>
      <p>
        The right to the protection of Personal Data is not an absolute right and in particular circumstances they
        could be limited, meaning that the Member will be provided with information that Eventify is allowed to
        provide to the Data Subject, considering that the right of access should not adversely affect the rights or
        freedoms of others, including trade secrets or intellectual property and in particular copyright protecting the software.
      </p>
      <p>
        If the Member considers that the Processing of the Member’s Personal Data infringes the Member’s rights and 
        interests under the Data Protection Legislation, the Member can lodge a complaint. The complaint can be 
        lodged to the Data State Inspectorate at <a href="https://www.dvi.gov.lv/lv" target='_blank'>www.dvi.gov.lv</a> or with a supervisory authority in the EU Member 
        State of the Member’s habitual residence or place of work. List of EU Member State supervisory 
        authorities available here: <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" target='_blank'>https://edpb.europa.eu/about-edpb/about-edpb/members_en</a>. 
      </p>

      <h2>
        11. Contact details
      </h2>
      <p>
        Members may contact Eventify with any request, withdrawal of consent, update of data processing 
        permission, data subject rights or complaint regarding the Processing of Personal Data. 
        <br />
        Contact details of Eventify are available on Website. 
      </p>

      <h2>
        12. Validity and amendments of the Privacy Policy
      </h2>
      <p>
        Eventify is entitled to unilaterally amend the Privacy Policy at any time, in compliance with the Regulatory Legislation, 
        by notifying the Members of any amendments via Eventify Website or email, not later than one month prior to the 
        amendments entering into force unless earlier notification is not needed to ensure compliance with the Regulatory Legislation.
      </p>

      <p>
        Privacy Policy entered into force in September 27, 2022, and the latest version is available on the Website.
      </p>


      

    </div>
  );
};

PrivacyPolicyContentEn.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicyContentEn.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicyContentEn;
