import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Button, Form } from '../../components';

import css from './TopbarSearchForm.module.css';
import KeywordSearchInput from '../../components/KeywordSearchInput/KeywordSearchInput';
import debounce from 'lodash/debounce';
import { value } from 'lodash/seq';
import { keyword } from 'chalk';

const identity = v => v;
const DEBOUNCE_WAIT_TIME = 400;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
    this.shortKeywordTimeout = null;
  }

  debouncedSubmit = debounce(this.onChange, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  handleChangeWithDebounce = (values, form) => {
    const hasKeywordValue = values && values.keyword;

    if (!hasKeywordValue) {
      this.props.onSubmit();
    }

    const keywordValue = hasKeywordValue ? values.keyword : '';

    if (!hasKeywordValue || (hasKeywordValue && keywordValue.length >= 3)) {
      if (this.shortKeywordTimeout) {
        window.clearTimeout(this.shortKeywordTimeout);
      }
      return this.debouncedSubmit(keywordValue);
    }
  };

  onChange(keyword) {
    if (keyword) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ keyword });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            form,
            currentSearchParams,
            values
          } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          return (
            <Form
              className={classes}
              onSubmit={preventFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <Field
                name="keyword"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    if(!isMobile) {
                      this.handleChangeWithDebounce(value, form);
                    }
                  };

                  const keywordValue = values?.keyword ? values?.keyword : '';

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <>
                      <KeywordSearchInput
                        className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                        iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                        inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                        predictionsClassName={
                          isMobile ? css.mobilePredictions : css.desktopPredictions
                        }
                        predictionsAttributionClassName={
                          isMobile ? css.mobilePredictionsAttribution : null
                        }
                        placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                        closeOnBlur={!isMobile}
                        inputRef={node => {
                          this.searchInput = node;
                        }}
                        input={searchInput}
                        meta={meta}
                        initialValue={currentSearchParams?.keywords || null}
                      />
                      {isMobile && (
                        <Button
                          onClick={() => {
                            this.props.onSubmit(keywordValue);
                            // this.props.onClose()
                          }}
                          className={css.submitButton}
                          type='submit'>
                          <FormattedMessage id='SectionHero.browseButton' />
                        </Button>
                      )}
                    </>

                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
